import { Container, Row, Col, Card, Table, Modal } from "react-bootstrap"
import FormHeader from "../../../components/form-header/form-header"
import NewsLetterApiService from "../../../data/services/news-letter/news-letter-api-service"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import axios, { AxiosError } from "axios";
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useDispatch } from "react-redux"
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action"


const NewsLetterDatatable = () => {
    const newsLetterApiService: NewsLetterApiService = new NewsLetterApiService();
    const [newsLetterData, setNewsLetterData] = useState<any>([]);
    let navigate: any = useNavigate();
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('NEWS LETTER SUBSCRIPTION'));

    const getNewsletterData = async () => {
        try {
            let result = await newsLetterApiService.getAllNewsLetter();
            console.log("getNewsletterData", result);
            setNewsLetterData(result);
        } catch (error) {
            console.log("getNewsletterData Error");
            console.log(error);
        }
    }


    const openAlert = (data: any) => {
        confirmAlert({
            title: "Alert Message",
            message: " Are you sure want to Delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteNewsletter(data),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };

    const deleteNewsletter = async (id: any) => {
        console.log('Attribute Id=' + id);
        try {

            await newsLetterApiService.deleteNewsletter(id);
            toast.success('Email Deleted Successfully', { containerId: 'TR' });
            await getNewsletterData();
            navigate('/main-layout/news-letter-datatable');
            return;

        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
        }

    }
    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    }
    const addLocation = () => {
        navigate('/main-layout/add-location')
    }


    useEffect(() => {
        getNewsletterData();
    }, []);

    return <>
        <Container fluid>
            <Row >
                <Col className="col-sm-12">
                    <Card className="card">
                        <Col className="card-header">
                        </Col>
                        <Card.Body className="card-body p-4">
                            <Col className="table-responsive table-desi p-4">
                                <Table className=' all-package table-category' responsive bordered size="white">
                                    <thead >
                                        <tr>
                                            <th>Email</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {newsLetterData.map((data: any, idx: any) => (
                                            <tr>
                                                <td data-field="email">{data.email}</td>
                                                <td>
                                                    <a href="javascript:void(0)" onClick={() => openAlert(data.id)} className="cursor-pointer">
                                                        <FontAwesomeIcon icon={faTrash} title="Delete variant" className='me-2 font-success' />
                                                    </a>
                                                </td>
                                            </tr>

                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>

        </Container>
    </>
}
export default NewsLetterDatatable