import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Modal, Row, Table, } from "react-bootstrap"
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import FormHeader from "../../../components/form-header/form-header"
import VariantRuleApiService from "../../../data/services/variant-rule/variant-rule-api-service";
import VariantTypeApiService from "../../../data/services/variant-type/variant-type-api-service";

let variantTypes: any[] = [];

const AddVariantRule = () => {
    let navigate: any = useNavigate()
    const variantRuleApiService: VariantRuleApiService = new VariantRuleApiService();
    const variantTypeApiService: VariantTypeApiService = new VariantTypeApiService();
    const [variantRuleName, setVariantRuleName] = useState('')
    const [variantTypeData, setVariantTypeData] = useState<any[]>([]);
    const [combinations, setCombinations] = useState<any[]>([]);
    const [, setVariantRule] = useState<any>([]);
    const [show, setShow] = useState(false);
    const [selectedVariant, setSelectedVariant] = useState('');
    const [variantRuleData, setVariantRuleData] = useState<any[]>([]);

    const checkVariantAlreadySelected = (variantTypeId:string) => {
        for(let i =0;i < variantRuleData.length;i++) {
            if(variantTypeId === variantRuleData[i].id) {
                return true;
            }
        }
        return false;
    }

    const handleShow = () => {
        if(variantRuleData.length > 0) {
            const finalArray:any[] = []
            variantTypes.map((variantType:any) => {
                const isVariantTypeSelected:boolean = checkVariantAlreadySelected(variantType.id);
                if(!isVariantTypeSelected){
                    finalArray.push(variantType);
                }
            });
            variantTypes = finalArray;
        }
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
        setSelectedVariant('');
    };

    const onVariantTypeChange = (selectedValue:any) => {
        setSelectedVariant(selectedValue);
    };
      

    const saveVariantType = async (event:any) => {
        event.preventDefault();
        if (!variantRuleName) {
            toast.error("Please Enter Rule name", { containerId: 'TR' });
            return;
        }
        if(variantRuleData.length == 0) {
            toast.error("Please create variant combination", { containerId: 'TR' });
            return;
        }
        try {
            const variantTypeIds:any[] = variantRuleData.map(variantType => variantType.id);
            const variantTypes:any[] = []
            for(let i =0;i < variantTypeIds.length;i++) {
                const variantType:any = {};
                variantType.variantTypeId = variantTypeIds[i];
                variantTypes.push(variantType);
            }
            let payload = {
                ruleName: variantRuleName,
                variantTypeIds: variantTypes
            }
            await variantRuleApiService.createVariantRule(payload);
            toast.success('Variant Rule Created Successfully', { containerId: 'TR' });
            navigate('/main-layout/variant-rule-datatable')
        } catch (error: any) {
            console.log("Variant Rule Error");
            toast.error(error.response.data.message, { containerId: 'TR' });
        }
    }

    const getTemplateData = async () => {
        try {
            let data: any[] = await variantTypeApiService.getAllVariantType();
            variantTypes = [...data];
        } catch (error) {
            console.log(error)
        }
    }

    const handleAddClick = () => {
        
        if (selectedVariant) {
            const selectedVariantType:any = variantTypes.find((variantType) => variantType.id == selectedVariant);
            variantRuleData.push(selectedVariantType);
            setVariantRuleData([...variantRuleData]);
            console.log(variantRuleData);
            setSelectedVariant('');
            handleClose();
        }
    };

    const removeVariant = (item:any, idx:number) => {
        variantRuleData.splice(idx, 1);
        setVariantRuleData([...variantRuleData]);
        variantTypes.push(item);
    }

    useEffect(() => {
        getTemplateData();
    }, []);

    return <>
        <FormHeader title="Add Variant Rule" />
        <Container fluid>
            <Row>
                <Col className="">
                    <Container fluid>
                    <Card className="my-5 p-4">
                        <Row>
                            <Form noValidate className="needs-validation user-add" onSubmit={saveVariantType}>
                                <Form.Group className="mb-3" controlId="productNameCtrlId">
                                    <Row>
                                        <Col md={2}>
                                            <Form.Label>Variant Rule Name</Form.Label>
                                        </Col>
                                        <Col md={8}>

                                            <Form.Control
                                                className="w-75"
                                                value={variantRuleName}
                                                autoComplete="off"
                                                onChange={e => { setVariantRuleName(e.target.value) }}
                                                type="text" required />
                                        </Col>
                                        <Form.Group className="mb-3" controlId="productNameCtrlId">
                                            <Row >
                                                <Col md={2}>
                                                    <Form.Label>Variant Type</Form.Label>
                                                </Col>
                                                <Col md={8}>
                                                    <Row>

                                                    <Col>
                                                            <Button type="button" className="my-2" onClick={handleShow}>Add VariantType</Button>

                                                        </Col>
                                                        
                                                    </Row>

                                                    <Button type="submit" className="btn btn-primary">Save</Button>

                                                </Col>
                                            </Row>
                                        </Form.Group>

                                    </Row>
                                </Form.Group>

                            </Form>
                            {/* <Dialog ref={(component) => { this.dialog = component }} /> */}
                            
                            <Card.Body className="card-body p-4">
                            <Col className="table-responsive table-desi">
                                <Table striped bordered hover variant="white">
                                    <thead >
                                        <tr>
                                           
                                            <th>Variant Type</th>
                                             <th>Action</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                    {variantRuleData.map((data:any, idx:any) => (                                            <tr>
                                                <td data-fiels="name">{data.variantType}</td>
                                                <a href="javascript:void(0)" onClick={() => removeVariant(data, idx)}>
                                                            <FontAwesomeIcon icon={faTrash} title="delete Variant Type" className='me-2 font-success' />
                                                        </a>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Card.Body>
                        </Row>
                        </Card>
                    </Container>

                </Col>
            </Row>
        </Container>
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                <Row>
                    <Col md={8}>
                        <select className="form-control"
                            onChange={(e) => { onVariantTypeChange( e.target.value) }}>
                            <option value="">Select VariantType</option>
                            {variantTypes.map((item: any, idx: any) => (
                                <option key={idx} value={item.id}>{item.variantType}</option>
                            ))}
                        </select>
                    </Col>
                    <Col >
                        <Button type="button" className="my-2 btn-danger mx-2" onClick={handleAddClick}>Add</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>

    </>
}
export default AddVariantRule

