import HttpClientWrapper from "../../api/http-client-wrapper";

class LocationApiService{


    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    createLocation= async (payload:any) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/locations', payload);
            console.log("LocationApiService createLocation() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getAllLocation = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/locations');
            console.log("LocationApiService getAllLocation() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getLocationById = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/locations/' + id);
            console.log("LocationApiService getLocationById() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    editLocation = async (id: any,payload:any ) => {
        try {
            let data: any = await this.httpClientWrapper.put('/v1/locations/' + id, payload);
            console.log("LocationApiService editLocation() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    deleteLocation = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.delete('/v1/locations/' + id);
            console.log("LocationApiService deleteLocation() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

}
export default LocationApiService