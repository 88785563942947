import { faCirclePlus, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Card, Button, Table, Modal, Form } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action";
import RoleApiService from "../../../data/services/role/role-api-service";
import { hasAccessPrivilege } from "../../../account/helper/access-privilege";
import { UserContextData, UserContext } from "../../../context/user-context";

const PrivilegeDataTable = () => {
    const roleApiService: RoleApiService = new RoleApiService();
    const [roleData, setRoleData] = useState<any>([]);
    let navigate: any = useNavigate();
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('ROLE PRIVILEGE'));
    const [privileges, setPrivileges] = useState([]);
    const userContextData: any = useContext<UserContextData>({ ...UserContext });
    const [hasEditRolePrivilege, setHasEditRolePrivilege] = useState(false);
    const [hasDeleteRolePrivilege, setHasDeleteRolePrivilege] = useState(false);
    const [hasAddRolePrivilege, setHasAddRolePrivilege] = useState(false);

    const checkPermission = () => {
        console.log('userContextData', userContextData);
        const editRolePrivilege = hasAccessPrivilege(userContextData, "PRIVILEGE", "EDIT_PRIVILEGE");
        setHasEditRolePrivilege(editRolePrivilege);
        const deleteRolePrivilege = hasAccessPrivilege(userContextData, "PRIVILEGE", "DELETE_PRIVILEGE");
        setHasDeleteRolePrivilege(deleteRolePrivilege);
        const addRolePrivilege = hasAccessPrivilege(userContextData, "PRIVILEGE", "ADD_PRIVILEGE");
        setHasAddRolePrivilege(addRolePrivilege);

    }

    const getRoleData = async () => {
        try {
            let result = await roleApiService.getAllRole();
            console.log("getRoleData", result);
            setRoleData(result);
        } catch (error) {
            console.log("getRoleData Error");
            console.log(error);
        }
    }
    const updatePrivilege = (id: any) => {
        navigate('/main-layout/edit-privilege', { state: { roleId: id } });
    }

    const addPrivilege = () => {
        navigate('/main-layout/add-privilege')
    }
    const deleteRole = async (id: any) => {
        console.log('Role Id=' + id);
        try {

            await roleApiService.deleteRole(id);
            toast.success('Role Deleted Successfully', { containerId: 'TR' });
            await getRoleData();
            navigate('/main-layout/role-datatable');
            return;

        } catch (error) {
            console.log("ERROR occured deleteRole() error=== " + JSON.stringify(error));
        }
    }
    const openAlert = (data: any) => {
        confirmAlert({
            title: "Alert Message",
            message: " Are you sure want to Delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteRole(data),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };


    useEffect(() => {
        getRoleData();
        checkPermission();
    }, []);


    return <>
        <Container fluid>
            <Row className="row">
                <Col className="col-sm-12">
                    <Card className="card">
                        <Col className="card-header">
                            {hasAddRolePrivilege && <Button type="button" onClick={addPrivilege}
                                className="btn btn-primary add-row mt-md-0 mt-2">
                                <FontAwesomeIcon icon={faCirclePlus} />
                                &nbsp;Add New
                            </Button>
                            }
                        </Col>

                        <Card.Body className="card-body p-4">
                            <Col className="table-responsive table-desi">
                                <Table responsive className=' all-package table-category' striped variant="white">
                                    <thead >
                                        <tr>
                                            <th>Role</th>
                                            {
                                                (hasEditRolePrivilege) && <th>Actions</th>
                                            }                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userContextData.role.roleCode != 'SUPER_ADMIN' && roleData.map((data: any, idx: any) => (
                                            data.roleCode != 'SUPER_ADMIN' && (
                                                <tr>

                                                    <td data-field="name">{data.name}</td>
                                                    {hasEditRolePrivilege && data.roleCode != 'SUPER_ADMIN' &&
                                                        <td>
                                                            <a onClick={() => updatePrivilege(data.id)}>
                                                                <FontAwesomeIcon icon={faPenToSquare} title="Edit Role Type" className='me-2 mx-2 font-success' />
                                                            </a>
                                                            <a href="javascript:void(0)" onClick={() => openAlert(data.id)} className="cursor-pointer">
                                                                <FontAwesomeIcon icon={faTrash} title="Delete Role with Privileges" className='me-2 mx-2 delete-btn' />
                                                            </a>
                                                        </td>
                                                    }
                                                </tr>
                                            )

                                        ))}

                                        {userContextData.role.roleCode == 'SUPER_ADMIN' && roleData.map((data: any, idx: any) => (

                                            <tr>

                                                <td data-field="name">{data.name}</td>
                                                {hasEditRolePrivilege &&
                                                    <td>
                                                        <a onClick={() => updatePrivilege(data.id)}>
                                                            <FontAwesomeIcon icon={faPenToSquare} title="Edit Role Type" className='me-2 mx-2 font-success' />
                                                        </a>
                                                        <a href="javascript:void(0)" onClick={() => openAlert(data.id)} className="cursor-pointer">
                                                            <FontAwesomeIcon icon={faTrash} title="Delete Role with Privileges" className='me-2 mx-2 delete-btn' />
                                                        </a>
                                                    </td>
                                                }
                                            </tr>


                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>

}

export default PrivilegeDataTable