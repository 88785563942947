import { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Dropdown, ButtonGroup } from "react-bootstrap"
import { useNavigate } from "react-router";
import FormHeader from "../../../components/form-header/form-header";
import CategoryDataTable from "./category-datatable";
import CategoryTreeView from "./category-treeview";
import { useDispatch, useSelector } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action";
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hasAccessPrivilege } from "../../../account/helper/access-privilege";
import { UserContextData, UserContext } from "../../../context/user-context";


const CategoryContainer = () => {

    const [activeView, setActiveView] = useState('list view');
    const dispatch = useDispatch();
    const userContextData: UserContextData = useContext<UserContextData>({ ...UserContext });
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('CATEGORIES'));
    let navigate: any = useNavigate();
    const [hasAddCategoryPrivilege, setHasAddCategoryPrivilege] = useState(false);

    const handleViewChange = (type: any) => {
        setActiveView(type);
    };

    const addCategories = () => {
        navigate('/main-layout/add-categories')
    }

    const checkPermission = () => {
        const addCategoryPrivilege = hasAccessPrivilege(userContextData, "CATEGORIES", "ADD_CATEGORY");
        setHasAddCategoryPrivilege(addCategoryPrivilege);
    }

    useEffect(() => {
        checkPermission();
    }, [userContextData]);

    return <>

        <Container fluid>
            <Row className="row">
                <Col className="col-sm-12">
                    <Card className="mb-5 p-4">
                        <Col className="card-header">
                            <Dropdown className="w-auto mb-2">
                                <Dropdown.Toggle split variant="primary" id="dropdown-split-basic">
                                    {activeView} &nbsp; &nbsp;
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleViewChange('list view')}>
                                        List View
                                    </Dropdown.Item>

                                    <Dropdown.Item onClick={() => handleViewChange('tree view')}>
                                        Tree View
                                    </Dropdown.Item>

                                </Dropdown.Menu>

                            </Dropdown>

                            {hasAddCategoryPrivilege && <Button onClick={addCategories}
                                className="btn btn-primary add-row mt-md-0 m-2">
                                <FontAwesomeIcon icon={faCirclePlus} />
                                &nbsp; Add New</Button>
                            }
                        </Col>
                        <Card.Body className="card-body p-4">

                            {activeView === 'tree view' ? (
                                <CategoryTreeView />
                            ) : (
                                <CategoryDataTable />
                            )}

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
}
export default CategoryContainer