import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BannerImageApiService from "../../data/services/banner-image/banner-image-api-service";

const AddBannerImage = () => {

    const bannerImageApiService = new BannerImageApiService();

    const [isNewBannerImage, setIsNewBannerImage] = useState("false");

    const bannerImageInput: any = useRef(null);

    const [bannerImage, setBannerImage] = useState<any>();

    const [sortOrder, setSortOrder] = useState('')

    const [title, setTitle] = useState('')

    const [subTitle, setSubTitle] = useState('')


    let navigate: any = useNavigate()

    const onBannerImageUploadListener = (event: any) => {
        const file = event.target.files[0];
        if (file && file.size !== 0) {
            let bannerImage: any = {};
            bannerImage.file = file;
            bannerImage.imagePath = URL.createObjectURL(bannerImage.file);
            setIsNewBannerImage("true");
            setBannerImage(bannerImage);
        } else {
            setBannerImage(null);
        }
    }

    const clearCoverImage = () => {
        setIsNewBannerImage("false");
        setBannerImage(null);
    }

    const submitBannerImage = async () => {
        try {
            let formData: FormData = new FormData();
            formData.append('sortOrder', sortOrder)
            formData.append('title', title)
            formData.append('subTitle', subTitle)
            formData.append('bannerImage', bannerImage.file);
            await bannerImageApiService.createBannerImage(formData);
            toast.success('Banner Image Saved Successfully', { containerId: 'TR' });
            navigate('/main-layout/banner-image-datatable');
        } catch (error: any) {
            console.log(error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    return <>
        <div className="page-body">
            <div className="container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="page-header-left">
                                <h3>Banner Image
                                    <small>Multikart Admin panel</small>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid bulk-cate">
                <div className="card ">
                    <div className="card-body">
                        <form className="dropzone digits" id="singleFileUpload">
                            <div>
                                <input type="file" ref={bannerImageInput}
                                    onChange={onBannerImageUploadListener}
                                />
                                <h4 className="mb-0 f-w-600">Drop files here or click to upload.</h4>
                            </div>
                        </form>
                        <div>
                            {
                                bannerImage ? (<div className="col-md-6 mt-2" >
                                    <div className="col-md-12">
                                        <div className="col-md-6"><img src={bannerImage.imagePath} width={300} height={200}></img></div>
                                        <div className="col-md-3">
                                            <button type="button" className="btn btn-danger btn-sm rounded-pill mt-2" aria-label="Close" onClick={() => clearCoverImage()}>
                                                <span className="" aria-hidden="true">Remove</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>) : null
                            }
                        </div>

                        <form className="needs-validation user-add mt-5">

                            <div className="form-group row">
                                <label
                                    className="col-xl-3 col-md-4"><span>*</span>Sort Order</label>
                                <div className="col-xl-8 col-md-7">
                                    <input className="form-control"
                                        type="text" value={sortOrder}
                                        onChange={e => { setSortOrder(e.target.value) }}
                                        required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label
                                    className="col-xl-3 col-md-4"><span>*</span>Title</label>
                                <div className="col-xl-8 col-md-7">
                                    <input className="form-control"
                                        type="text" value={title}
                                        onChange={e => { setTitle(e.target.value) }}
                                        required />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label
                                    className="col-xl-3 col-md-4"><span>*</span>Sub Title</label>
                                <div className="col-xl-8 col-md-7">
                                    <input className="form-control"
                                        type="text" value={subTitle}
                                        onChange={e => { setSubTitle(e.target.value) }}
                                        required />
                                </div>
                            </div>
                            <div className="pull-right">
                                <button type="button" className="btn btn-primary"
                                onClick={submitBannerImage}
                                >Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default AddBannerImage