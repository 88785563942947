const formHeaderReducer = (state = [], action: {type:any, payload:any}) => {
    switch(action.type){
        case "SAVE_FORM_HEADER":
            return action.payload;
        case "ERASE_FORM_HEADER":
            return null;
        default:
            return state;
    }
}
export default formHeaderReducer;