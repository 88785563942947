import HttpClientWrapper from "../../api/http-client-wrapper";

class RoleApiService {

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    createRole = async (payload:any) => {
        try {
            let data: any = await this.httpClientWrapper.post('v1/roles', payload);
            console.log("RoleApiService createRole() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getAllRole = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('v1/roles');
            console.log("RoleApiService getAllRole() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }


    getRoleById = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/roles/' + id);
            console.log("RoleApiService getRoleById() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    editRole = async (id: any,payload:any ) => {
        try {
            let data: any = await this.httpClientWrapper.put('/v1/roles/' + id, payload);
            console.log("RoleApiService editRole() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }


    deleteRole = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.delete('/v1/roles/' + id);
            console.log("RoleApiService deleteRole() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }
}
export default RoleApiService