import { Button, Col, Container, Form, Row } from "react-bootstrap";

import './privilege.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import FeatureApiService from "../../../data/services/feature/feature-api-service";
import React from "react";
import { toast } from "react-toastify";

const data = [

    {
        name: "Categories",
        path: '/main-layout/categories-datatable',
        subMenu: [],
        roles: ['ADMIN', 'STANDARD_USER'],
    },
    {
        name: "Product Catalogue",
        path: '',
        roles: ['ADMIN', 'STANDARD_USER'],
        subMenu: [

            {
                name: "Product",
                path: '/main-layout/product-container',

            },
            {
                name: "Base Product",
                path: '/main-layout/base-product-datatable',

            }

        ]
    },

    {
        name: "Configuration",
        path: '',
        roles: ['ADMIN'],
        subMenu: [

            {
                name: "Variants",
                path: '/main-layout/variant-datatable',

            },
            {
                name: "Attributes",
                path: '/main-layout/attribute-datatable',

            },

            {
                name: "Locations",
                path: '/main-layout/location-datatable',

            },
            {
                name: "Email Configuration",
                path: '/main-layout/email-config-datatable',
            },
            {
                name: "Users",
                path: '/main-layout/account-datatable',
            },
            {
                name: "Role",
                path: '/main-layout/role-datatable',

            },

        ]
    },

    {
        name: "Others",
        path: '',
        roles: ['ADMIN', 'STANDARD_USER'],
        subMenu: [
            {
                name: "Request for Quotes",
                path: '/main-layout/quote-datatable',

            },
            {
                name: "News Letter Subscription",
                path: '/main-layout/news-letter-datatable',

            }
        ]
    },
]
const AddPrevilage = (props: any) => {
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const featureApiService: FeatureApiService = new FeatureApiService();
    const [showSelectedItems, setShowSelectedItems] = useState<boolean>(false);
    const [featureData, setFeatureData] = useState<any>([]);
    const [selectedFeature, setSelectedVariant] = useState('');
    const [selectedFeatures, setSelectedFeatures] = useState<any>([]);
    const [featurePrivileges, setFeaturePrivileges] = useState<any>([]);
    const [selectedPrivilege, setSelectedPrivilege] = useState<any>([]);
    const handleCheckboxChange = (itemIndex: number, subItemIndex?: number) => {
        let updatedItems: any[] = [];

        if (subItemIndex !== undefined) {
            const selectedItem = featureData[itemIndex].featureActions[subItemIndex];
            updatedItems = selectedItems.filter(item => item.id !== selectedItem.id);
            if (updatedItems.length === selectedItems.length) {
                updatedItems.push(selectedItem);
            }
        } else {
            const selectedItem = featureData[itemIndex];
            const actionsToRemove = selectedItems.filter(item => item.parentId === selectedItem.id);
            updatedItems = selectedItems.filter(item => !actionsToRemove.includes(item));
            if (!updatedItems.includes(selectedItem)) {
                updatedItems.push(selectedItem);
            }
            updatedItems.push(...selectedItem.featureActions);
        }

        setSelectedItems(updatedItems);
    };

    const handleAddClick = () => {
        try{
            if(!selectedFeature){
                throw new Error("Please select a Feature");
            }
            if(!selectedFeature){
                throw new Error("Please select a Feature");
            }

        }catch(error:any){
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const onFeatureSelectedHandler = (e:any) => {
        const selectedFeatureId = e.target.value;
        if(!selectedFeatureId){
            setSelectedVariant('');
            setFeaturePrivileges([]);
            return;
        }
        const feature: any = featureData.find((f:any) => f.id === selectedFeatureId);
        setSelectedVariant(feature);
        for (let i = 0; i < feature.featureActions.length; i++){
            feature.featureAction[i].checked = hightlightOnPrivilegeAlreadySelected(selectedFeatureId,feature.featureActions[i].id );
        }
        setFeaturePrivileges(feature.featureActions);

    }
    const hightlightOnPrivilegeAlreadySelected = (featureId: string, privilegeId: string) =>{
        if (selectedFeatures.length > 0){
            let tmpPrivileges: any[] = [];
            for (let i = 0; i < selectedFeatures.length; i++) {
                if (selectedFeatures[i].id == featureId) {
                    tmpPrivileges = selectedFeatures[i].featureActions;
                    break;
                }
            }
            if(tmpPrivileges.length > 0){
                let highlightPrivilege: boolean = false;
                for (let i = 0; i < tmpPrivileges.length; i++){
                    if(tmpPrivileges[i].id == featureId){
                        highlightPrivilege = true;
                        break;
                    }
                   
                }
                return highlightPrivilege;

            }
        }
        return false;

    }

    const onFeaturePrivilegeSelected = (e: any, privilege: any) => {
        for (let i = 0; i < featurePrivileges.length; i++){
            if(featurePrivileges[i].id == privilege.id) {
                featurePrivileges[i].checked = e.target.checked;
            }
        }
        setSelectedFeatures([...featurePrivileges]);
    }




    const getFeatureData = async () => {
        try {
            let result = await featureApiService.getAllFeature();
            console.log("getFeatureData", result);
            setFeatureData(result);
        } catch (error) {
            console.log("getFeatureData Error");
            console.log(error);
        }
    }

    const handleArrowClick = () => {
        setShowSelectedItems(!showSelectedItems);
    };

    useEffect(() => {
        getFeatureData();
    }, []);
    
    return (
        <Container>
            <Row>
                <Col sm={5}>
                    <Col className="box">
                        {featureData.map((item:any, idx:any) => (
                            <Col key={idx}>
                                <Col className="d-flex justify-content-between">
                                    <b>{item.name}</b>
                                    <Form.Check
                                        type="checkbox"
                                        onChange={(e) => onFeatureSelectedHandler(e)}
                                    />
                                </Col>
                            </Col>
                        ))}
                    </Col>
                </Col>
                <Col sm={2} className="arrow">
                    <FontAwesomeIcon icon={faArrowRight} beat onClick={handleArrowClick} />
                </Col>
                <Col sm={5}>
                    <Col className="box">
                        {showSelectedItems && selectedItems.map((item, idx) => (
                            <Col key={idx}>
                                {item.name}
                                <Row>
                                {item.featureActions && item.featureActions.map((item1:any, index:any) => (
                                    <React.Fragment key={index}>
                                    <Col sm={6}>
                                        <Form.Check
                                        type="checkbox"
                                        label={item1.actionName} 
                                        onChange={(e) => onFeaturePrivilegeSelected(e, item1)}/>
                                    </Col>
                                    {index % 2 === 1 && <hr />} 
                                    </React.Fragment>
                                ))}
                                </Row>
                            </Col>
                            
                        ))}
                    </Col>
                </Col>
            </Row>
        </Container>
    );

}
export default AddPrevilage