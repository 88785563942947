import HttpClientWrapper from "../../api/http-client-wrapper";

class BaseProductApiService {

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getAllBaseProduct = async (searchQuery?:string) => {
        try {
            let data: any = await this.httpClientWrapper.get('v1/baseProducts');
            console.log("BaseProductApiService getAllBaseProducts() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getBaseProductById = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('v1/baseProducts/' + id);
            console.log("BaseProductApiService getAllBaseProductById() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    deleteBaseProduct = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.delete('v1/baseProducts/' + id);
            console.log("BaseProductApiService deleteBaseProduct() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    unlinkBaseProduct = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.put('v1/baseProducts/' + id+"/unlink");
            console.log("BaseProductApiService unlinkBaseProduct() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    updateBaseProduct = async(id:any, payload:any) => {
        try{
            let data:any= await this.httpClientWrapper.put('v1/baseProducts/'+id, payload );
            console.log("BaseProductApiService updateBaseProduct() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    createBaseProduct = async (payload: any) => {
        try {
            let data: any = await this.httpClientWrapper.post('v1/baseProducts', payload);
            console.log("BaseProductApiService createBaseProduct() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

}
export default BaseProductApiService