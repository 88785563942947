import { useLocation, useNavigate } from "react-router-dom";
import VariantApiService from "../../../data/services/variant/variant-api-service";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FormHeader from "../../../components/form-header/form-header";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import axios, { AxiosError } from 'axios';
import { useDispatch } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action";
import { faXmark, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditVariant = () => {
    const variantApiService: VariantApiService = new VariantApiService();
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('EDIT VARIANT'));
    const [name, setName] = useState('');
    const [variantOptions, setVariantOptions] = useState<any[]>([]);
    const [variantData, setVariantData] = useState<any[]>([]);
    let navigate: any = useNavigate();
    const { state } = useLocation();
    const id = state?.id;

    const handleInputChange = (index: any, value: any) => {
        const newVariantOptions = [...variantOptions];
        newVariantOptions[index] = { option: value };
        setVariantOptions(newVariantOptions);
    };

    const addVariantOptionField = () => {
        setVariantOptions([...variantOptions, { option: '' }]);
    };

    const removeVariantNameField = (index: any) => {
        const newVariantOptions = [...variantOptions];
        newVariantOptions.splice(index, 1);
        setVariantOptions(newVariantOptions);
    };

    const goBack = () => {
        navigate('/main-layout/variant-datatable');
    }
    const updateVariant = async (event: any) => {
        try {
            event.preventDefault();
            if (!name) {
                toast.error("Please Enter name", { containerId: 'TR' });
                return;
            }
            if (!variantOptions || variantOptions.length == 0) {
                toast.error("Please enter Variant Options ", { containerId: 'TR' });
                return;
            }
            for (let i = 0; i < variantOptions.length; i++) {
                if (!variantOptions[i].option) {
                    throw new Error("Variant Option shoudn't be empty = Row " + (i + 1));
                }
            }
            let payload: any = {
                variantName: name,
                variantOptions: variantOptions
            }
            await variantApiService.editVariant(id, payload);
            toast.success('Variant Created Successfully', { containerId: 'TR' });
            navigate('/main-layout/variant-datatable');
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log("Categories Create Failed", error);
            console.log(error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }
    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    };

    const getVariantData = async () => {
        try {
            let data = await variantApiService.getVariantById(id);
            setData(data);
            console.log('category data', data);
            let variantData = await variantApiService.getAllVariant();
            setVariantData(variantData);
        } catch (error) {
            console.log(error);
        }

    }
    const setData = (data: any) => {
        setName(data.variantName);
        if (data.options) {
            setVariantOptions(data.options);
        }
    }

    useEffect(() => {
        getVariantData();
    }, []);

    return <>
        {/* <FormHeader title="Edit Variant" /> */}
        <Container fluid>
            <Row>
                <Col className="">
                    <Container fluid>
                        <Card>
                            <Row>
                                <Form noValidate className="needs-validation user-add" onSubmit={updateVariant}>
                                    <Row>
                                        <Form.Group className="mb-3" controlId="productNameCtrlId">
                                            <Row>
                                                <Col md={2}>
                                                    <Form.Label>Variant Name<span>*</span></Form.Label>

                                                </Col>
                                                <Col md={4}>
                                                    <Form.Control
                                                        type="text"
                                                        value={name}
                                                        autoComplete="off"
                                                        onChange={e => { setName(e.target.value) }}
                                                        required
                                                    />
                                                </Col>

                                            </Row>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="productNameCtrlId">
                                            <Row>
                                                <Col md={2}>

                                                    <Form.Label>Variant Options</Form.Label>
                                                </Col>
                                                <Col md={6}>
                                                    {variantOptions && variantOptions.map((variantName, index) => (
                                                        <Row>
                                                            <Col md={8}>
                                                                <Form.Control key={index}
                                                                    type="text"
                                                                    autoComplete="off"
                                                                    value={variantName.option}
                                                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                                                    required />
                                                            </Col>
                                                            <Col md={4} className="mt-2">
                                                                <FontAwesomeIcon className="mx-2" icon={faXmark} size="2xl" textAnchor="Remove" style={{ cursor: 'pointer' }} onClick={() => removeVariantNameField(index)} />
                                                            </Col>
                                                        </Row>


                                                    ))}
                                                    <Row>
                                                        <Col md={5} className="mt-3">
                                                            <Button onClick={addVariantOptionField}><FontAwesomeIcon icon={faCirclePlus} />
                                                                &nbsp;Add New</Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Row>

                                    {variantOptions.length > 0 && <Form.Group>
                                        <Row >
                                            <Col xs={11} className="d-flex justify-content-end">
                                                <Button type="button" variant="danger" className="secondary mx-2" onClick={goBack} >
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" >Update</Button>

                                            </Col>
                                        </Row>

                                    </Form.Group>}
                                </Form>
                            </Row>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>

    </>
}
export default EditVariant