import * as React from 'react';
import { faChevronDown, faChevronRight, faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CategoriesApiService from '../../../data/services/category/category-api-service';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import './category-treeview.css'
import { Card, Col } from 'react-bootstrap';
const TreeView = require('treeview-react-bootstrap').default;

const CategoryTreeView = () => {
    const categoriesApiService: CategoriesApiService = new CategoriesApiService;
    const [categoriesData, setCategoriesData] = useState<any>([]);
    let navigate: any = useNavigate();

    const getCategoriesData = async () => {
        try {
            let result = await categoriesApiService.getAllCategoriesTreeView();
            console.log("getCategoriesData", result);
            setCategoriesData(result);
        } catch (error) {
            console.log("getCategoriesData Error");
            console.log(error);
        }
    }

    useEffect(() => {
        getCategoriesData();
    }, []);

    return (
        <Col className='mx-auto tree-view'>

            <TreeView data={categoriesData} />,
        
        </Col>
    );
}

export default CategoryTreeView