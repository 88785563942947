import { useState } from "react";
import AttributeApiService from "../../../data/services/attribute-api-service/attribute-api-service";
import { useNavigate } from "react-router-dom";
import { title } from "process";
import { toast } from "react-toastify";
import { AttributePayload } from "../../../data/services/attribute-api-service/attribute-payload";
import FormHeader from "../../../components/form-header/form-header";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action";
import { AxiosError } from "axios";

const attributeType = [
    {
        name: 'PRODUCT_SPECIFICATION',
        code: 'product_specification'
    },
]
const AddAttribute = () => {
    const attributeApiService: AttributeApiService = new AttributeApiService();
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('ADD ATTRIBUTE'));
    const [name, setName] = useState('')
    const [code, setCode] = useState('')
    const [type, setType] = useState('')

    let navigate: any = useNavigate()

    // const validateAttribute = async () => {
    //     if (!name) {
    //         toast.error("Please Enter name", { containerId: 'TR' });
    //         return;
    //     }
    //     if (!code) {
    //         toast.error("Please Select  Code ", { containerId: 'TR' });
    //     }
    //     if (!type) {
    //         toast.error("Please Select  Type ", { containerId: 'TR' });
    //     }
    //     await saveAttribute();
    // }

    const handleChange = (id: any) => {
        setType(id);
    };

    const goBack = () => {
        navigate('/main-layout/attribute-datatable');
    }

    const saveAttribute = async (event:any) => {
        try {
            event.preventDefault();
            if (!name) {
                toast.error("Please Enter name", { containerId: 'TR' });
                return;
            }
            if (!code) {
                toast.error("Please Select  Code ", { containerId: 'TR' });
                return;
            }
            if (!type) {
                toast.error("Please Select  Type ", { containerId: 'TR' });
                return;
            }
            
            let payload: AttributePayload = {
                name: name,
                code: code,
                type: type
            }
            await attributeApiService.createAttribute(payload);
            toast.success('Attribute Created Successfully', { containerId: 'TR' });
            navigate('/main-layout/attribute-datatable')
        } catch (error: any) {
            console.log("save attribute Error");
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    return <>
      {/* <FormHeader title="Add Attribute" /> */}

      <Container fluid>
            <Row>
                <Col className="">
                    <Container fluid>
                        <Card>
                            <Row>
                            <Form noValidate className="needs-validation user-add" onSubmit={saveAttribute}>
                                <Form.Group className="mb-3" controlId="productNameCtrlId">
                                <Row>
                                            <Col md={2}>
                                                <Form.Label>Name</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="text" value={name}
                                                    autoComplete="off"
                                                    onChange={e => { setName(e.target.value) }}
                                                    required />
                                            </Col>

                                        </Row>
                                        </Form.Group>
                                    <Form.Group className="mb-3" controlId="categorySort">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Code</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="text" 
                                                    value={code}
                                                    autoComplete="off"
                                                    onChange={e => { setCode(e.target.value) }}
                                                    required />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>

                                                <Form.Label>Type</Form.Label>
                                            </Col>
                                            <Col md={6}>


                                                <select className="form-control" value={type} onChange={(e) => { handleChange(e.target.value) }} >
                                                    <option value="">Select Type</option>
                                                    {attributeType.map((data: any, idx: any) => (
                                                        <option key={idx} value={data.name}>{data.name}</option>
                                                    ))}
                                                </select>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group>
                                    <Row >
                                        <Col xs={8} className="d-flex justify-content-end mt-3">
                                        <Button type="button" variant="danger" className="mx-2" onClick={goBack} >
                                                Cancel
                                            </Button> 
                                            <Button type="submit" variant="primary" > &nbsp;Save&nbsp;</Button>
                                               
                                       </Col>
                                    </Row>

                                </Form.Group>

                                </Form>
                            </Row>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>
        </>
}
export default AddAttribute