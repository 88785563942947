import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Image, Modal } from "react-bootstrap";
import ProductApiService from "../../../data/services/product/product-api-service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";

const DeleteProductGallery = (props: any) => {

    const productApiService: ProductApiService = new ProductApiService();
    const [selectedGalleryImages, setSelectedGalleryImages] = useState<any[]>([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleShow = () => setShowConfirmation(true);
    const handleCloseDialog = () => setShowConfirmation(false);
    const [index ,setIndex] = useState(0);
    const [id ,setId] = useState(0);


    const handleClose = () => {
        props.handleClose();
    };

    const getProductGalleryById = async () => {
        try {
            let data = await productApiService.getProductImgGalleryById(props.productId);
            console.log(data);

            setSelectedGalleryImages([...data]);
        } catch (error) {
            console.log("getProductById Error");
            console.log(error);
        }
    }

    const deleteGalleryById = async (idx:any, galleryImageId:string) => {
        try {
            await productApiService.deleteProductImgGallery(galleryImageId);
            toast.success("Image deleted Succesfully !", { containerId: 'TR' });
            getProductGalleryById();
            selectedGalleryImages.splice(0, idx)
            setSelectedGalleryImages([...selectedGalleryImages]);
        } catch (error) {
            console.log("getProductById Error");
            console.log(error);
        }
    }

    const confirmDelete = (idx:any, id: any) => {
        setShowConfirmation(true);
        setIndex(idx);
        setId(id);
    };

    const handleConfirm = (idx:any, id: any) => {
        deleteGalleryById(idx, id);
        setShowConfirmation(false);
    };

    useEffect(() => {
        getProductGalleryById();
        return ()=>{
            setSelectedGalleryImages([]);
          }
    }, []);

    return (
        <>
                <Modal show={showConfirmation} onHide={handleCloseDialog}>
            <Modal.Header closeButton>
                <Modal.Title>Alert Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure want to Delete ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" size="sm" onClick={handleCloseDialog}>
                    Cancel
                </Button>
                <Button variant="primary" size="sm" onClick={() => handleConfirm(index, id)} >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
            <Container>
                <Row className="mt-5">
                    <Col className="">
                        <Container fluid>
                            <Row>
                                
                            </Row>
                            <Row>
                                {
                                    selectedGalleryImages && selectedGalleryImages.map((item: any, idx: any) => (
                                        <Col xs={6} md={4}>
                                            <Image src={item.imageURL} thumbnail />
                                            <a href="javascript:void(0)" onClick={() => confirmDelete(idx, item.id)} className="cursor-pointer">
                                                <FontAwesomeIcon icon={faTrash} title="delete Image" className='me-2 mx-2 delete-btn' />
                                            </a>
                                        </Col>
                                        
                                    ))
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    );

}
export default DeleteProductGallery;