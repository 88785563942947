import React from 'react';
import logo from './logo.svg';
import './App.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRouter from './router/app-router';
import Header from './layouts/header/header';
import './css/style.css';
import './css/select2.min.css';
import './css/prism.css';
import './css/linearicons.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; 


function App() {
  return (
    <>
      <ToastContainer enableMultiContainer containerId={'TR'} position={toast.POSITION.TOP_RIGHT} />
      <ToastContainer enableMultiContainer containerId={'BC'} position={toast.POSITION.BOTTOM_CENTER} />
      <AppRouter />
    </>
  );
}

export default App;
