import HttpClientWrapper from "../../api/http-client-wrapper";

class ProductApiService {

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getAllProduct = async(url: any) => {
        try{
            let data:any= await this.httpClientWrapper.get('/v1/products'+url );
            console.log("ProductApiService getAllProduct() response data"+JSON.stringify(data));
            return data;  
        }catch (error){
            throw error;
        }
    }

    exportProduct = async() => {
        try{
            let data:any= await this.httpClientWrapper.download('/v1/products/export');
            return data;     
        }catch (error){
            throw error;
        }
    }

    getAllProductTemplate = async() => {
        try{
            let data:any= await this.httpClientWrapper.get('/v1/products/templates' );
            console.log("ProductApiService getAllProductTemplate() response data"+JSON.stringify(data));
            return data;     
        }catch (error){
            throw error;
        }
    }

    getProductById = async(id:any) => {
        try{
            let data:any= await this.httpClientWrapper.get('/v1/products/'+id+'/details');
            console.log("ProductApiService getProductById() response data"+JSON.stringify(data));
            return data;     
        }catch (error){
            throw error;
        } 
    }

    deleteDefaultImageById = async(id:any) => {
        try{
            let data:any= await this.httpClientWrapper.put('/v1/products/'+id+'/deleteDefaultImage');
            console.log("ProductApiService deleteDefaultImageById() response data"+JSON.stringify(data));
            return data;     
        }catch (error){
            throw error;
        } 
    }

    createProduct = async(formData:FormData) => {
        try{
            let data:any= await this.httpClientWrapper.postFormData('/v1/products',formData );
            console.log("ProductApiService createProduct() response data"+JSON.stringify(data));
            return data;     
        }catch (error){
            throw error;
        }
    }

    createProductImportFile = async(formData:FormData) => {
        try{
            let data:any= await this.httpClientWrapper.postFormData('/v1/products/import',formData );
            console.log("ProductApiService createProductImportFile() response data"+JSON.stringify(data));
            return data;     
        }catch (error){
            throw error;
        }
    }

    createProductGallery = async(id:any,formData:FormData) => {
        try{
            let data:any= await this.httpClientWrapper.postFormData('/v1/products/'+id+'/gallery',formData );
            console.log("ProductApiService createProduct() response data"+JSON.stringify(data));
            return data;     
        }catch (error){
            throw error;
        }
    }

    getProductImgGalleryById = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/products/' + id + "/productGallery");
            console.log("GetProductById() response data " + JSON.stringify(data));
            return data;
        } catch (error) {
            throw error;
        }
    }
    
    deleteProductImgGallery = async(id:any) => {
        try{
            let data:any= await this.httpClientWrapper.delete('/v1/products/productGallery/'+id );
            console.log("ProductApiService deleteProductImgGallery() response data"+JSON.stringify(data));
            return data;     
        }catch (error){
            throw error;
        }
    }

    editProduct = async(id:any, formData:FormData) => {
        try{
            let data:any= await this.httpClientWrapper.putFormData('/v1/products/'+id, formData );
            console.log("ProductApiService editProduct() response data"+JSON.stringify(data));
            return data;     
        }catch (error){
            throw error;
        }
    }

    deleteProduct = async(id:any) => {
        try{
            let data:any= await this.httpClientWrapper.delete('/v1/products/'+id );
            console.log("ProductApiService deleteProduct() response data"+JSON.stringify(data));
            return data;     
        }catch (error){
            throw error;
        }
    }

    

}
export default ProductApiService;