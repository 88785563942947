import HttpClientWrapper from "../../api/http-client-wrapper";

class NewsLetterApiService{
    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    createNewsLetter = async (payload:any) => {
        try {
            let data: any = await this.httpClientWrapper.post('v1/newsLetter', payload);
            console.log("NewsLetterApiService createNewsLetter() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }


    getAllNewsLetter = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('v1/newsLetters');
            console.log("NewsLetterApiService getAllNewsLetter() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    deleteNewsletter = async(id:any) => {
        try{
            let data:any= await this.httpClientWrapper.delete('/v1/newsLetters/'+id );
            console.log("ProductApiService deleteProduct() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }
}
export default NewsLetterApiService