import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ProductApiService from "../../../data/services/product/product-api-service";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const ProductFileUpload = () => {

    const productApiService = new ProductApiService();
    let navigate: any = useNavigate()

    const [isNewProductImage, setIsNewProductImage] = useState("false");
    const productImageInput: any = useRef(null);
    const [productFile, setProductImage] = useState<any>();
    const [disableUploadbtn, setDisableExportBtn] = useState(false);

    const onProductUploadListener = (event: any) => {
        const file = event.target.files[0];
        if (file && file.size !== 0) {
            let productFile: any = {};
            productFile.file = file;
            productFile.imagePath = URL.createObjectURL(productFile.file);
            setIsNewProductImage("true");
            setProductImage(productFile);
        } else {
            setProductImage(null);
        }
    }

    const submitProductFile = async () => {
        try {
            setDisableExportBtn(true);
            let formData: FormData = new FormData();
            formData.append('file', productFile.file);
            await productApiService.createProductImportFile(formData);
            setDisableExportBtn(false);
            toast.success('Product File Saved Successfully', { containerId: 'TR' });
            navigate('/main-layout/product-container');
        } catch (error: any) {
            setDisableExportBtn(false);
            console.log(error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const clearCoverImage = () => {
        setIsNewProductImage("false");
        setProductImage(null);
    }

    const canecl = () => {
        navigate('/main-layout/product-container');
    }

    return <>
        <div className="container-fluid bulk-cate">
            <div className="card ">
                <div className="card-body">
                    <form className="dropzone digits" id="singleFileUpload">
                        <div>
                            <input type="file" ref={productImageInput}
                                onChange={onProductUploadListener}
                                accept=".xlsx"
                            />
                            <h4 className="mb-0 f-w-600">Drop files here or click to upload.</h4>
                        </div>
                    </form>
                    <div>
                        {
                            productFile ? (<div className="col-md-6 mt-2" >
                                <div className="col-md-12">
                                    <div className="col-md-6">
                                        <img src={productFile.imagePath} width={300} height={200}></img></div>
                                    <div className="col-md-3">
                                        <button type="button" className="btn btn-danger btn-sm rounded-pill mt-2" aria-label="Close" onClick={() => clearCoverImage()}>
                                            <span className="" aria-hidden="true">Remove</span>
                                        </button>
                                    </div>
                                </div>
                            </div>) : null
                        }
                    </div>

                    <div className="pull-right">
                        <button className="btn btn-primary mx-4" onClick={canecl}>Cancel</button>
                        <button type="button" className="btn btn-primary my-4"
                            onClick={submitProductFile}
                        >Upload {disableUploadbtn && <Spinner animation="border" size="sm"/> } </button>
                    </div>
                </div>
            </div>
        </div>

    </>

}
export default ProductFileUpload