import HttpClientWrapper from "../../api/http-client-wrapper";

class QuoteApiService {
    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getAllQuote = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/carts');
            console.log("QuoteApiService getAllQuote() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    deleteQuote = async (id: any) => {
        try {
            let data: any = await this.httpClientWrapper.delete('/v1/carts/'+id);
            console.log("QuoteApiService deleteLocation() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

}
export default QuoteApiService