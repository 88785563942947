import HttpClientWrapper from "../../api/http-client-wrapper";

class EmailConfigApiService {

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }


    getAllEmailConfig = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/emailConfigs');
            console.log("EmailConfigApiService getAllEmailConfig() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getAllLocations =async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/locations');
            console.log("EmailConfigApiService getAllLocations() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    getAllEmailConfigById = async (id:any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/emailConfigs/'+ id );
            console.log("EmailConfigApiService getAllEmailConfig() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }

    createEmailConfig = async (payload: any) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/emailConfigs', payload);
            console.log("EmailConfigApiService createEmailConfig() response data" + JSON.stringify(data));
            return (data);
        } catch (error) {
            throw error;
        }
    }
    updateEmailConfig = async(id:any, payload:any) => {
        try{
            let data:any= await this.httpClientWrapper.put('/v1/emailConfigs/'+id, payload );
            console.log("EmailConfigApiService updateCategory() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    deleteEmailConfig = async(id:any) => {
        try{
            let data:any= await this.httpClientWrapper.delete('/v1/emailConfigs/'+id );
            console.log("EmailConfigApiService deleteEmailConfig() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }
   

}
export default EmailConfigApiService