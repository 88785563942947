import { useContext, useEffect, useState } from "react"
import { Button, Card, Col, Container, Dropdown, Modal, Row, Spinner } from "react-bootstrap"
import FormHeader from "../../components/form-header/form-header"
import ProductDatatable from "./product-datatable"
import { useNavigate } from "react-router-dom";
import ProductApiService from "../../data/services/product/product-api-service"
import ProductGridView from "./product-grid-view"
import ProductListView from "./product-list-view"
import Paginator from "../../common/pagenator/pagenator";
import axios, { AxiosError } from 'axios';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../data/state/form-header-action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faArrowUpFromBracket, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import EditProduct from "./edit-product";
import LoginState from "../../data/state/login-state";
import { hasAccessPrivilege } from "../../account/helper/access-privilege";
import { UserContext, UserContextData } from "../../context/user-context";


const ProductContainer = () => {
  const productApiService: ProductApiService = new ProductApiService;
  const [productData, setProductData] = useState<any>([]);
  const [totalPages, setTotalpages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [page, setPage] = useState(1);
  const [viewType, setViewType] = useState('table view');
  const [disableExportBtn, setDisableExportBtn] = useState(false);
  const [isLoading] = useState(false);
  const [options, setOptions] = useState<[]>([]);
  const productApiSearch: ProductApiService = new ProductApiService();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [productId, setProductId] = useState(null);
  // for permission change start
  const userContextData: UserContextData = useContext<UserContextData>({...UserContext});
  const [hasAddProductPrivilege, setHasAddProductPrivilege] = useState(false);
  const [hasExportProductPrivilege, setHasExportProductPrivilege] = useState(false);
  const [hasImportProductPrivilege, setHasImportProductPrivilege] = useState(false);
  // for permission change end
  
  let navigate: any = useNavigate();

  const dispatch = useDispatch();

  const addProduct = () => {
    navigate('/main-layout/add-product')
  }

  const productFileUpload = () => {
    navigate('/main-layout/product-file-upload')
  }

  const handleViewChange = (type: any) => {
    setViewType(type);
  };


  const getAllProductData = async (pageNo?: number) => {
    try {
      let url = "?page=" + page + "&pageSize=" + 5;

      if (pageNo) {
        url = "?page=" + pageNo + "&pageSize=" + 5;
      }
      let result = await productApiService.getAllProduct(url);
      console.log("getAllProductData", result);
      paginationSetup(result.meta);
      setProductData(result.items);
    } catch (error) {
      console.log("getAllProductData Error");
      console.log(error);
    }
  }
  const paginationSetup = (meta: any) => {
    setTotalpages(meta.totalPages);
    setActivePage(meta.currentPage);
  }

  const exportProduct = async () => {
    setDisableExportBtn(true);
    try {
      let result = await productApiService.exportProduct();
      setDisableExportBtn(false);
      downloadFile(result, "product.xlsx");
    } catch (error: any) {
      setDisableExportBtn(false);
      if (axios.isAxiosError(error)) {
        handleAxiosError(error);
        return;
      }
      console.log(error);
      toast.error(error.message, { containerId: 'TR' });
    }
  }

  const downloadFile = (blobData: any, fileName: string) => {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    const blobFile = new Blob([blobData]);
    const url = window.URL.createObjectURL(blobFile);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const handleAxiosError = (error: AxiosError) => {
    if (error.response) {
      const errorMessage = (error.response.data as { message: string }).message;
      toast.error(errorMessage, { containerId: 'TR' });
    } else if (error.request) {
      toast.error('No response from server', { containerId: 'TR' });
    } else {
      toast.error(error.message, { containerId: 'TR' });
    }
  };
  const handleSearch = async (query: string) => {
    try {
      let url = "?page=" + 1 + "&pageSize=" + 1000;

      if (query) {
        url = url + '&searchKeyword=' + query;
      }
      let result = await productApiSearch.getAllProduct(url);
      console.log("getDimensionData", result);
      setOptions(result.items);
    } catch (error) {
      console.log("getDimensionData Error");
      console.log(error);
    }
  };

  const onSelected = (selectedItem: any) => {
    const productId = selectedItem?.[0]?.['productId'];
    setProductId(productId);
    handleShow();
  }

  const checkPermission = () => {
    const addProductPrivilege = hasAccessPrivilege(userContextData, "PRODUCT", "ADD_PRODUCT");
    setHasAddProductPrivilege(addProductPrivilege);
    const importProductPrivilege = hasAccessPrivilege(userContextData, "PRODUCT", "IMPORT_PRODUCT");
    setHasImportProductPrivilege(importProductPrivilege);
    const exportProductPrivilege = hasAccessPrivilege(userContextData, "PRODUCT", "EXPORT_PRODUCT");
    setHasExportProductPrivilege(exportProductPrivilege);
  }
  
  useEffect(() => {
    dispatch(saveFormHeaderAction('PRODUCTS'));
    checkPermission();
}, []);

  return <>
    <Container fluid>
      <Row>
        <Col>
          <Card className="mb-5 p-4">
            <Row>
              <Col md={4}>
                <AsyncTypeahead
                  filterBy={() => true}
                  id="product-search-box"
                  isLoading={isLoading}
                  labelKey="name"
                  minLength={3}
                  onChange={onSelected}
                  onSearch={handleSearch}
                  options={options}
                  placeholder="Search by products"
                  className="mx-2 p-3"

                />
              </Col>
              <Col md={8} className="mt-1">
                <Card.Header>


                 {hasImportProductPrivilege && 
                  <Button
                    onClick={productFileUpload}
                    className="btn btn-primary add-row mt-md-0 mx-2"
                  >
                    <FontAwesomeIcon icon={faDownload} />&nbsp;
                    Import
                  </Button>
                  }

                  { hasExportProductPrivilege && <Button disabled={disableExportBtn}
                    className="btn btn-primary add-row mt-md-0 mx-2 text-end"
                    onClick={() => exportProduct()}>
                    <FontAwesomeIcon icon={faArrowUpFromBracket} /> &nbsp;
                    Export {disableExportBtn && <Spinner animation="border" size="sm" />}
                  </Button>
                  }


                  <Dropdown className="w-auto mx-2">
                    <Dropdown.Toggle split variant="primary" id="dropdown-split-basic">
                      {viewType}&nbsp;&nbsp;
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleViewChange('table view')}>
                        Table View
                      </Dropdown.Item>

                      <Dropdown.Item onClick={() => handleViewChange('grid view')}>
                        Grid View
                      </Dropdown.Item>

                    </Dropdown.Menu>

                  </Dropdown>
                  {hasAddProductPrivilege && <Button type="button" onClick={addProduct}
                    className="btn btn-primary add-row mt-md-0 mt-2 mx-2">
                    <FontAwesomeIcon icon={faCirclePlus} />
                    &nbsp;Add New
                  </Button>}
                </Card.Header>
              </Col>
            </Row>
            <Card.Body className="card-body p-4">
              <Row>
                {viewType === 'grid view' ? (

                  <ProductGridView />

                ) : (

                  <ProductDatatable />

                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    <Modal show={show} dialogClassName="modal-90w"
      onHide={handleClose}
      backdrop="static"
      keyboard={false} size="xl">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <EditProduct updateProduct={onSelected} productId={productId} handleClose={handleClose} />
      </Modal.Body>
    </Modal>
  </>
}
export default ProductContainer