export const saveFormHeaderAction = (title:any) => {
    clearFormHeaderAction();
    return {
        type: 'SAVE_FORM_HEADER',
        payload: title
    }
}

export const clearFormHeaderAction = () => {
    return {
        type: 'ERASE_FORM_HEADER',
        payload: null
    }
}