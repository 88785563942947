import { Button, Card, Col, Container, Modal, Row, Table } from "react-bootstrap"
import QuoteApiService from "../../data/services/quote/quote-api-service"
import { useEffect, useState } from "react";
import { Form, useNavigate } from "react-router-dom";
import FormHeader from "../../components/form-header/form-header";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../data/state/form-header-action";



const QuoteDataTable = () => {
    const qouteApiService: QuoteApiService = new QuoteApiService();
    const [quoteData, setQuoteData] = useState<any>([]);
    const [cartItem, setCartItem] = useState<any>([]);
    let navigate: any = useNavigate();
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('REQUEST FOR QUOTE'));

    const getQuoteData = async () => {
        try {
            let result = await qouteApiService.getAllQuote();
            console.log("getQuoteData", result);
            setQuoteData(result.items);
            setCartItem(result.items.cartItems)
        } catch (error) {
            console.log("getQuote Error");
            console.log(error);
        }
    }
    function refreshPage() {
        window.location.reload();

    }
    const [on, setOn] = useState(false);
    const handleOn = () => setOn(true);
    const handleOff = () => setOn(false);

    const openDialog = (data: any) => {
        setOn(true);
        setCartItem(data);
    }

    const deleteQuote = async (id: any) => {
        console.log('Product Id=' + id);
        try {
            await qouteApiService.deleteQuote(id);
            toast.success('Quote Deleted Successfully', { containerId: 'TR' });
            await getQuoteData();
            return;

        } catch (error) {
            console.log("ERROR occured deleteProduct() error=== " + JSON.stringify(error));
        }
    }

    const confirmDelete = (id: any) => {
        confirmAlert({
            title: "Confirm",
            message: " Are you sure want to Delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteQuote(id),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };




    useEffect(() => {
        getQuoteData();
    }, []);

    return <>
        {/* <FormHeader title="Quote" /> */}
        <Container fluid>
            <Row className="row">
                <Col className="col-sm-12">
                    <Card className="card">
                    <Col className="card-header">
                            <Button type="button" onClick={refreshPage}
                                className="btn btn-primary add-row mt-md-0 mt-2">
                                {/* <FontAwesomeIcon icon={faCirclePlus} /> */}
                                &nbsp;Refresh
                            </Button>

                        </Col>
                        <Card.Body className="card-body p-4">
                            <Col className="table-responsive table-desi">
                                <Table responsive className=' all-package table-category' striped variant="white">
                                    <thead >
                                        <tr>
                                        <th>FirstName</th>
                                            <th>LastName</th>
                                            <th>Email</th>
                                            <th>Contact</th>
                                            <th>CompanyName</th>
                                            <th>Location</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {quoteData.map((data: any, idx: any) => (
                                            <tr key={idx} style={{ cursor: 'pointer' }}title="Click to View" onClick={() => openDialog(data.cartItems)}>

                                                <td data-field="firstName">{data.first_name}</td>
                                                <td data-field="lastName">{data.last_name}</td>
                                                <td data-field="email">{data.email}</td>
                                                <td data-field="contactNumber">{data.contact_number}</td>
                                                <td data-field="companyName">{data.company_name}</td>
                                                <td data-field="location">{data.location}</td>
                                                <td>
                                                    {/* <button type="button" className="btn btn-outline-success" onClick={() => openDialog(data.cartItems)}>
                                                        View Details</button> */}
                                                    <a href="javascript:void(0)" onClick={() => confirmDelete(data.id)} className="cursor-pointer">
                                                        <FontAwesomeIcon icon={faTrash} title="delete Quote" className='me-2 mx-2 delete-btn' />
                                                    </a>                                    </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <Modal size='lg' show={on} onHide={handleOff} dialogClassName="custom-modal">
                                    <Modal.Header closeButton>
                                        <h4 className="center">Product Details</h4>
                                    </Modal.Header>
                                    <table className="table all-package table-category " id="editableTable">
                                        <Table striped bordered hover variant="white">
                                            <thead >
                                                <tr>
                                                    <th>Product Name</th>
                                                    <th>MOQ</th>
                                                    <th>Quantity</th>
                                                    <th>ProductType</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cartItem && cartItem.map((data: any, idx: any) => (
                                                    <tr>
                                                        <td data-field="firstName">{data.product.name}</td>
                                                        <td data-field="lastName">{data.product.moq}</td>
                                                        <td data-field="quantity">{data.qty}</td>
                                                        <td data-field="productType">{data.product.productType}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </table>
                                </Modal>
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

    </>

}
export default QuoteDataTable