import { Button, Col, Form, Row } from "react-bootstrap"
import ProductApiService from "../../../data/services/product/product-api-service";

const LinkProduct = (props: any) => {
    const productApiService: ProductApiService = new ProductApiService;
    const tabNumber: number = 2;

    const validateAndSaveSpecificationsFormData = () => {
        try {
            const productFormData = props?.productFormData;

        } catch (error: any) {
            throw error;
        }
    }

    const submitLinkProductFormData = () => {
        try {
            validateAndSaveSpecificationsFormData();
            props.onNextCallback((tabNumber + 1));
        } catch (error: any) {
            throw (error);
        }
    }

    const goBack = () => {
        try {
            props.onPreviousCallback((tabNumber - 1));
        } catch (error: any) {
            throw (error);
        }
    }

    return <>
    <Row>
    <Col md={2}>
    <Form.Label>Link Product<span>*</span></Form.Label>
    </Col>
        <Col md={3}>
        <select className="form-control">
            <option value="">Select Prodcut</option>
            <option value="">Alternate Prodcut</option>
            <option value="">Special Prodcut</option>
        </select>
        </Col>
    </Row>
   
       
        <Form.Group className="mb-3" controlId="productMoqCtrlId">
                                    <Row className="justify-content-end">
                                        <Col md={2}>
                                            <Button type="button" className="btn btn-primary my-4"
                                                onClick={goBack}
                                            >back</Button>
                                             <Button type="button" className="btn btn-primary my-4"
                                                onClick={submitLinkProductFormData}
                                            >Next</Button>
                                        </Col>
                                        {/* <Col md={8} >
                                           
                                        </Col> */}
                                    </Row>
                                </Form.Group>
    </>
}
export default LinkProduct