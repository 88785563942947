// import { faFacebookF} from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from "react";
import { AuthPayload } from "../data/services/auth/auth-payload";
import AuthApiService from "../data/services/auth/auth-api-service";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveLoginAction } from "../data/state/login-action";
import loginImg from "../assets/AdobeStock_590711715.png";
import { Button, Card, CardBody, Col, Container, Form, Row, } from "react-bootstrap";
import AccountApiService from "../data/services/account/account-api-service";
import axios, { AxiosError } from "axios";


const Login = () => {
    const authService: AuthApiService = new AuthApiService();
    const accountService: AccountApiService = new AccountApiService();
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [roleCode, setRoleCode] = useState('');
    let navigate: any = useNavigate();
    const dispatch = useDispatch();
    const [roleData, setRoleData] = useState<any>([]);
    const [showPassInput, setShowPassInput] = useState(true);

    const doLogin = async () => {
        if (!userName) {
            toast.error("Please Enter User Name", { containerId: 'TR' });
            return;
        }
        if (!password) {
            toast.error("Please Enter password", { containerId: 'TR' });
            return;
        }
        await executeLoginApi();
    }

    const executeLoginApi = async () => {
        try {
            let payload: AuthPayload = { username: userName, password: password, roleCode: roleCode };
            const data: any = await authService.doLogin(payload);
            dispatch(saveLoginAction(data?.data));
            toast.success('Successfully Login', { containerId: 'TR' });
            navigate('/main-layout/categories-datatable');
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log(" Login Failed", error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    };

    const fetchTemplateData = async () => {
        let res: any = await accountService.templateData();
        setRoleData([...res]);
    }

    const handleRoleChange = (value: any) => {
        setRoleCode(value);
    }

    const handleusernameChange = async (value:any) => {
        try {
            setUserName(value);
            const data: any = await authService.fetchByUsername(value);
            if(data){
                setShowPassInput(false);
            }
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log(" Login Failed", error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }
    useEffect(() => {
        fetchTemplateData();
    }, []);

    return <>

        <Container className="mt-5">
            <Row className="mt-5">
                <Card>
                    <Row>
                        <Col md={8}>
                            <img src={loginImg} alt="login" className="login-img" />
                        </Col>
                        <Col md={4}>
                            <div className="tab-content" id="top-tabContent">
                                <div className="tab-pane fade show active" id="top-profile" role="tabpanel"
                                    aria-labelledby="top-profile-tab">
                                    <form className="form-horizontal auth-form">

                                        <div className=" text-center">
                                            <span style={{ fontSize: '32px', fontWeight: 'bold' }}>
                                                Welcome to
                                            </span>
                                        </div>

                                        <div className=" text-center">
                                            <span style={{ color: '#5b8213', fontSize: '32px', fontWeight: 'bold' }}>
                                                Eco Packaging Direct
                                            </span>
                                        </div>
                                        <div className="text-center">
                                            <span style={{ fontSize: '32px', fontWeight: 'bold' }}>
                                                Backoffice
                                            </span>
                                        </div>

                                        <div className="form-group mt-4">
                                            <input type="text" className="form-control"
                                                value={userName} onChange={e => { setUserName(e.target.value) }}
                                                placeholder="Enter Your Email" id="exampleInputEmail1" />
                                        </div>
                                        <div className="form-group">
                                            <input type="password"
                                                value={password} onChange={e => { setPassword(e.target.value) }}
                                                className="form-control" placeholder="Password" />
                                        </div>
                            {/*       <select className="form-control" value={roleCode} onChange={(e) => { handleRoleChange(e.target.value) }} >
                                            <option value="">--Select Role--</option>
                                            {roleData.map((data: any, idx: any) => (
                                                <option key={idx} value={data.roleCode}>{data.name}</option>
                                            ))}
                                        </select> */}

                                        {/* <div className="form-terms">
                                            <div className="mt-2">
                                                <input type="checkbox" className="form-check-input"
                                                    id="customControlAutosizing" />
                                                <label className="form-check-label ps-2"
                                                    htmlFor="customControlAutosizing">Remember me</label>
                                            </div>
                                        </div> */}
                                        <div className="form-button text-center mt-3">
                                            <button className="btn btn-primary"
                                                onClick={doLogin}
                                                type="button">Login</button>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </Col>
                    </Row>

                </Card>

            </Row>

        </Container>
    </>
}

export default Login