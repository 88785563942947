import { useEffect } from "react";
import { FeaturePrivilege } from "../../context/feature-privilege";
import { UserContextData } from "../../context/user-context";

const getFeatureActions = (features:any[], featureCode: string) => {
    for(let i = 0;i < features.length;i++) {
        if(features[i].feature.featureCode == featureCode) {
            return features[i].privilegeActions;
        }
    }
}

export const hasAccessPrivilege = (data: UserContextData, featureCode: string, featureActionCode: string) => {
    
    if(!data) {
        return false;
    }
    const privilegeActions:any[] = getFeatureActions(data.featurePermissions, featureCode);
    console.log('privilegeActions', privilegeActions);
    if(privilegeActions) {
        for(let i = 0;i < privilegeActions.length;i++) {
            if(privilegeActions[i].featureAction.actionCode == featureActionCode) {
                return true;
            }
        }
    }
    return false;
}
