import { combineReducers } from "redux";
import loginReducer from "../data/state/login-reducer";
import formHeaderReducer from "../data/state/form-header-reducer";

const appReducers = combineReducers(
    {
        personData: loginReducer,
        title: formHeaderReducer,
    }
)

export default appReducers;