import { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../account/login";
import AddCategory from "../pages/master/category/add-category";
import MainLayout from "../main-layout/main-layout";
import ProductDatatable from "../pages/product/product-datatable";
import AddVariant from "../pages/master/variant/add-variant";
import VariantDatatable from "../pages/master/variant/variant-datatable";
import AddSubCategory from "../pages/master/category/add-sub-categy";
import AddBannerImage from "../pages/banner-image/add-banner-image";
import BannerImageDatatable from "../pages/banner-image/banner-image-datatable";
import AddProduct from "../pages/product/add-product";
import Quote from "../pages/quote/quote-datatable";
import AttributeDatatable from "../pages/master/attribute/attribute-datatable";
import AddAttribute from "../pages/master/attribute/add-attribute";
import EditAttribute from "../pages/master/attribute/edit-attribute";
import QuoteDataTable from "../pages/quote/quote-datatable";
import ProductBasicDetails from "../pages/product/components/product-basic-details";
import ProductSpecificiation from "../pages/product/components/product-specification";
import AddVariantRule from "../pages/master/variant-rule/add-variant-rule";
import EditVariantRule from "../pages/master/variant-rule/edit-variant-rule";
import VariantRuleDatatable from "../pages/master/variant-rule/variant-rule-datatable";
import EditVariant from "../pages/master/variant/edit-variant";
import ProductFileUpload from "../pages/product/components/product-file-upload";
import LinkProduct from "../pages/product/components/link-product";
import EditCategory from "../pages/master/category/edit-category";
import BaseProductDatatable from "../pages/base-product/base-product-datatable";
import AddBaseProduct from "../pages/base-product/add-base-product";
import EditBaseProduct from "../pages/base-product/edit-base-product";
import EditProduct from "../pages/product/edit-product";
import CategoryTreeView from "../pages/master/category/category-treeview";
import CategoryContainer from "../pages/master/category/category-container";
import ProductContainer from "../pages/product/product-container";
import LocationDatatable from "../pages/master/location/location-datatable";
import AddLocation from "../pages/master/location/add-location";
import EditLocation from "../pages/master/location/edit-location";
import AddEmailConfig from "../pages/email-config/add-email-config";
import EmailConfigDataTable from "../pages/email-config/email-config-datatable";
import EditEmailConfig from "../pages/email-config/edit-email-config";
import NewsLetterDatatable from "../pages/master/news-letter/news-letter-datatable";
import ChangePassword from "../account/change-password";
import CreateAccount from "../pages/master/account/create-account";
import AccountDatatable from "../pages/master/account/account-datatable";
import EditAcount from "../pages/master/account/edit-account";
import EditAccount from "../pages/master/account/edit-account";
import AddRole from "../pages/master/role/add-role";
import RoleDatatable from "../pages/master/role/role-datatable";
import EditRole from "../pages/master/role/edit-role";
import AddFeature from "../pages/master/features/add-features";
import FeatureDatatable from "../pages/master/features/feature-datatable";
import EditFeature from "../pages/master/features/edit-features";
import AddRolePermission from "../pages/master/permission/add-role-permission";
import PermissionDatatable from "../pages/master/permission/permission-datatable";
import EditRolePermission from "../pages/master/permission/edit-role-permission";
import ViewPrivilege from "../pages/master/privilege/add-privilege";
import PrivilegeDataTable from "../pages/master/privilege/privilege-datatable";
import EditPrivilege from "../pages/master/privilege/edit-privilege";


const AppRouter = () => {
    return <>
        <Suspense fallback={<span>Loading....</span>}>
            <Router>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/main-layout" element={<MainLayout />}>
                        <Route path="product-datatable" element={<ProductDatatable />} />
                        <Route path="add-product" element={<AddProduct />} />
                        <Route path="edit-product" element={<EditProduct />} />
                        <Route path="product-file-upload" element={<ProductFileUpload />} />
                        <Route path="add-variant" element={<AddVariant />} />
                        <Route path="edit-variant" element={<EditVariant />} />
                        <Route path="variant-datatable" element={<VariantDatatable />} />
                        <Route path="categories-datatable" element={<CategoryContainer/>} />
                        <Route path="add-categories" element={<AddCategory />} />
                        <Route path="edit-category" element={<EditCategory />} />
                        <Route path="add-sub-categories" element={<AddSubCategory />} />
                        <Route path="add-banner-image" element={<AddBannerImage />} />     
                        <Route path="banner-image-datatable" element={<BannerImageDatatable />} />              
                        <Route path="edit-banner-image" element={<AddBannerImage />} />     
                        <Route path="quote-datatable" element={<Quote />} />    
                        <Route path="attribute-datatable" element={<AttributeDatatable />} />    
                        <Route path="add-attribute" element={<AddAttribute/>} />  
                        <Route path="edit-attribute" element={<EditAttribute/>} />  
                        <Route path="quote-datatable" element={<QuoteDataTable />} />    
                        {/* <Route path="basic-details" element={<ProductBasicDetails />} />     */}
                        <Route path="specification-details" element={<ProductSpecificiation />} />  
                        <Route path="add-variant-rule" element={<AddVariantRule />} />  
                        <Route path="edit-variant-rule" element={<EditVariantRule />} />
                        <Route path="variant-rule-datatable" element={<VariantRuleDatatable />} />
                        <Route path="link-product" element={<LinkProduct />} />
                        <Route path="base-product-datatable" element={<BaseProductDatatable />} />
                        <Route path="add-base-product" element={<AddBaseProduct />} />
                        <Route path="edit-base-product" element={<EditBaseProduct/>} />
                        <Route path="categories-treeview" element={<CategoryTreeView />} />
                        <Route path="product-container" element={<ProductContainer />} />
                        <Route path="location-datatable" element={<LocationDatatable />} />
                        <Route path="add-location" element={<AddLocation />} />
                        <Route path="edit-location" element={<EditLocation />} />
                        <Route path="email-config-datatable" element={<EmailConfigDataTable />} />
                        <Route path="edit-email-config" element={<EditEmailConfig />} />
                        <Route path="add-email-config" element={<AddEmailConfig />} />
                        <Route path="news-letter-datatable" element={<NewsLetterDatatable />} />
                        <Route path="change-password" element={<ChangePassword />} />
                        <Route path="create-account" element={<CreateAccount/>}/>
                        <Route path="account-datatable" element={<AccountDatatable />} />
                        <Route path="edit-account" element={<EditAccount />} />
                        <Route path="add-role" element={<AddRole/>}/>
                        <Route path="role-datatable" element={<RoleDatatable />} />
                        <Route path="edit-role" element={<EditRole />} />
                        <Route path="add-feature" element={<AddFeature/>}/>
                        <Route path="feature-datatable" element={<FeatureDatatable />} />
                        <Route path="edit-feature" element={<EditFeature />} />
                        <Route path="add-role-permission" element={<AddRolePermission/>}/>
                        <Route path="permission-datatable" element={<PermissionDatatable />} />
                        <Route path="edit-role-permission" element={<EditRolePermission/>}/>
                        <Route path="privilege-datatable" element={<PrivilegeDataTable/>}/>
                        <Route path="add-privilege" element={<ViewPrivilege/>}/>
                        <Route path="edit-privilege" element={<EditPrivilege/>}/>

                    </Route>
                </Routes>
            </Router>
        </Suspense>
    </>
}





export default AppRouter