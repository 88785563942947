import { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row, Image, Tab } from "react-bootstrap";
import BaseProductApiService from "../../../data/services/base-product-api-service/base-product-api-service";
import { useNavigate } from "react-router-dom";
import ProductApiService from "../../../data/services/product/product-api-service";
import { toast } from "react-toastify";
import ProductTag from "./product-tag/product-tag";

const ProductBasicDetails = (props: any) => {
    const [tags, setTags] = useState<any>([
        { code: 'new_arrival', value: 'New Arrival', checked: false },
        { code: 'trending', value: 'Trending', checked: false },
        { code: 'special_product', value: 'Special Product', checked: false }
    ]);
    const baseProductApiService: BaseProductApiService = new BaseProductApiService;
    const productApiService: ProductApiService = new ProductApiService;
    const [name, setName] = useState("");
    const [description, setDescription] = useState('')
    const [searchKeyword, setSearchKeyword] = useState('')
    const [sortOrder, setSortOrder] = useState('')
    const [moq, setMoq] = useState('')
    const [sku, setSku] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [defaultImage, setDefaultImage] = useState<any>();
    const [previewImage, setPreviewImage] = useState<any>();
    const [baseProductData, setBaseProductData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<[]>([]);
    const [selectedVariantName, setSelectedVariantName] = useState('');
    const [variantOptions, setVariantOptions] = useState<any[]>([]);
    const [selectedBaseProduct, setSelectedBaseProduct] = useState<any[]>([]);
    const [variantData, setVariantData] = useState<any[]>([]);
    const [selectedOption, setSelectedOption] = useState('');
    const navigate: any = useNavigate();
    const [categoryData, setCategoryData] = useState<any>([]);
    const [selectedCategory, setSelectedCategory] = useState<any>([]);
    const [productTypeData, setProductTypeData] = useState<any>([]);
    const [productTagData, setProductTagData] = useState<any>([]);
    const [selectedProductType, setselectedProductType] = useState('NORMAL');
    const [selectedProductTag, setselectedProductTag] = useState('');
    const [isBaseProduct, setIsBaseProduct] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [seoTitle, setSeoTitle] = useState('')
    const [seoDescription, setSeoDescription] = useState('')
    const [seoKeywords, setSeoKeywords] = useState('')


    const handleProductImage = (e: any) => {
        let file: any = e.target.files[0];
        if (file) {
                console.log('Selected SVG file:', file);
                let previewImage: any = URL.createObjectURL(file);
                setPreviewImage(previewImage);
                setDefaultImage(file);
        }
        
    }

    const handleTagSelectionChange = (e: any, idx: any, item: any) => {
        item.checked = e.target.checked;
        tags[idx] = item;
        setTags([...tags]);
    };

    const getProductTemplate = async () => {
        try {
            let data = await productApiService.getAllProductTemplate();
            setCategoryData(data.categories);
            setProductTypeData(data.productTypeEnumValues);
            setProductTagData(data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleProductTypeChange = (e: any) => {
        const selectedValue = e.target.value;
        setselectedProductType(selectedValue);
        props.onProductTypeChangeListener(selectedValue);
    };

    const validateBasicInfoFormData = (): any => {
        if (!selectedProductType) {
            throw new Error("Product Type Should Not Be Empty");
        }
        if (!name) {
            throw new Error("Name Should Not Be Empty");
        }
        if (!selectedCategory) {
            throw new Error("Category Should Not Be Empty");
        }
        if (!description) {
            throw new Error("Description Should Not Be Empty");
        }

        if (!moq) {
            throw new Error("MOQ Should Not Be Empty");
        }
        if (!sku) {
            throw new Error("Sku Should Not Be Empty");
        }
    }

    const constructCategory = () => {
        const categories: any[] = [];
        for (let i = 0; i < categoryData.length; i++) {
            let category: any = {};
            if (categoryData[i].id == selectedCategory) {
                category = categoryData[i].id;
                categories.push(category);
            }
        }
        return categories;
    }

    const constructTag = () => {
        const tags: any[] = [];
        for (let i = 0; i < productTagData.length; i++) {
            let tag: any = {}
            if (productTagData[i].id == selectedProductTag) {
                tag = productTagData[i].id;
                tags.push(tag)
            }
        }
        return tags;
    }

    const validateAndSaveBasicInfoFormData = (): any => {
        try {
            validateBasicInfoFormData();
            if (props.productFormData.has('productType')) {
                props.productFormData.delete('productType');
            }
            props.productFormData.append('productType', selectedProductType);
            if (props.productFormData.has('isBaseProduct')) {
                props.productFormData.delete('isBaseProduct');
            }
            props.productFormData.append('isBaseProduct ', isBaseProduct);

            if (selectedProductType == 'NORMAL') {
                if (props.productFormData.has('baseProductId')) {
                    props.productFormData.delete('baseProductId');
                }
                if (props.productFormData.has('variants')) {
                    props.productFormData.delete('variants');
                }

            }

            if (props.productFormData.has('name')) {
                props.productFormData.delete('name');
            }
            props.productFormData.append('name', name);

            if (props.productFormData.has('category')) {
                props.productFormData.delete('category');
            }
            props.productFormData.append('category', JSON.stringify(constructCategory()));

            if (props.productFormData.has('description')) {
                props.productFormData.delete('description');
            }
            props.productFormData.append('description', description);

            if (props.productFormData.has('videoUrl')) {
                props.productFormData.delete('videoUrl');
            }
            props.productFormData.append('videoUrl', videoUrl);

            if (props.productFormData.has('searchKeyword')) {
                props.productFormData.delete('searchKeyword');
            }
            props.productFormData.append('searchKeyword', searchKeyword);

            if (props.productFormData.has('moq')) {
                props.productFormData.delete('moq');
            }
            props.productFormData.append('moq', moq);

            if (props.productFormData.has('sku')) {
                props.productFormData.delete('sku');
            }
            props.productFormData.append('sku', sku);


            if (props.productFormData.has('sortOrder')) {
                props.productFormData.delete('sortOrder');
            }
            props.productFormData.append('sortOrder', sortOrder);

            if (props.productFormData.has('file')) {
                props.productFormData.delete('file');
            }
            props.productFormData.append('file', defaultImage);
            let selectedTags: any[] = [];
            for (let i = 0; i < tags.length; i++) {
                if (tags[i].checked) {
                    selectedTags.push(tags[i].code);
                }
            }
            if (props.productFormData.has('tags')) {
                props.productFormData.delete('tags');
            }
            if (selectedTags.length > 0) {
                props.productFormData.append('tags', JSON.stringify(selectedTags));
            }
            if (props.productFormData.has('seoTitle')) {
                props.productFormData.delete('seoTitle');
            }
            props.productFormData.append('seoTitle', seoTitle);

            if (props.productFormData.has('seoDescription')) {
                props.productFormData.delete('seoDescription');
            }
            props.productFormData.append('seoDescription', seoDescription);

            if (props.productFormData.has('seoKeywords')) {
                props.productFormData.delete('seoKeywords');
            }
            props.productFormData.append('seoKeywords', seoKeywords);

        } catch (error: any) {
            throw error;
        }
    }

    const submitBasicInfoFormData = () => {
        try {
            validateAndSaveBasicInfoFormData();
            props.onNextCallback();
        } catch (error: any) {
            console.log(error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const goBack = () => {
        navigate('/main-layout/product-container');
    }

    const handleInputChange = (index: any, value: any) => {
        const newVariantOptions = [...variantOptions];
        newVariantOptions[index] = { option: value };
        setVariantOptions(newVariantOptions);
    };

    const handleMOQChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^[1-9]\d*$/.test(inputValue) || inputValue === '') {
            setMoq(inputValue);
        }
    };

    const handleCategoryChange = (event: any) => {
        const categoryId = event.target.value;
        // const selectedCategory:any = categoryData.find((c: { id: any; }) => c.id === categoryId);
        // let category:any = {};
        // category = selectedCategory.id;
        setSelectedCategory(categoryId);
    };

    const onBaseProductSelected = async (selectedItem: any) => {
        setSelectedBaseProduct(selectedItem);
        const baseProductDetails: any = await baseProductApiService.getBaseProductById(selectedItem[0].id);
        setVariantData(baseProductDetails.baseProductVariants);
    }

    const handleTagChange = (event: any) => {
        const tagId = event.target.value;
        setselectedProductTag(tagId);
    };

    const clearPreviewImage = () => {
        setPreviewImage(null); // or setPreviewImage('')
        setDefaultImage(null);
    };

    const handleSwitchChange = () => {
        setIsChecked(!isChecked);
    };

    useEffect(() => {
        getProductTemplate();
    }, []);


    return <>
        <Container>

            <Row className="mt-5">
                <Col className="">
                    <Container fluid>
                        <Row>
                            <Form noValidate>

                                <Form.Group controlId="productNameCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Name<span>*</span></Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                autoComplete="off"
                                                value={name}
                                                onChange={e => { setName(e.target.value); }}
                                                placeholder="Enter product Name" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>

                                </Form.Group><Form.Group className="mt-3" controlId="productCategoryCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Select Category<span>*</span></Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <select className="form-control "
                                                onChange={(e) => { handleCategoryChange(e); }}>
                                                <option value="">--Select--</option>
                                                {categoryData.length > 0 && categoryData.map((item: any, idx: any) => (
                                                    <option key={idx} value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="productCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Select Product Type <span>*</span></Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <select className="form-control" onChange={handleProductTypeChange}
                                                value={selectedProductType}>
                                                {productTypeData.length > 0 && productTypeData.map((item: any, idx: any) => (
                                                    <option key={idx} value={item.code}>{item.value}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="productDescriptionCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Description<span>*</span></Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control as="textarea" rows={6}
                                                value={description}
                                                maxLength={600}
                                                onChange={e => { setDescription(e.target.value); }}
                                                placeholder="Enter Description (600 max)"></Form.Control>
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="productMoqCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>MOQ<span>*</span></Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                autoComplete="off"
                                                value={moq}
                                                onChange={handleMOQChange}
                                                placeholder="Enter MOQ"></Form.Control>
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="productMoqCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>SKU<span>*</span></Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={sku}
                                                autoComplete="off"
                                                onChange={e => { setSku(e.target.value); }}
                                                placeholder="Enter SKU"></Form.Control>
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group><Form.Group className="my-3" controlId="productSearchKeywordCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Search Keyword</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={searchKeyword}
                                                autoComplete="off"
                                                onChange={e => { setSearchKeyword(e.target.value); }}
                                                placeholder="Enter Search KeyWord"></Form.Control>
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="needs-validation my-3" controlId="productCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Tags</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Row>
                                                {
                                                    tags.length > 0 && tags.map((item: any, idx: any) => (
                                                        <Form.Check
                                                            inline
                                                            onChange={(e) => handleTagSelectionChange(e, idx, item)}
                                                            checked={item.checked}
                                                            type='checkbox'
                                                            name="group1"
                                                            label={`${item.value}`}
                                                        />
                                                    ))
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="productDescriptionCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Seo Title<span>*</span></Form.Label>
                                        </Col>
                                        <Col md={8}>
                                        <Form.Control type="text"
                                                value={seoTitle}
                                                autoComplete="off"
                                                onChange={e => { setSeoTitle(e.target.value); }}
                                                placeholder="Enter Seo Title"></Form.Control>
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="productDescriptionCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Seo Description<span>*</span></Form.Label>
                                        </Col>
                                        <Col md={8}>
                                        <Form.Control type="text"
                                                value={seoDescription}
                                                autoComplete="off"
                                                onChange={e => { setSeoDescription(e.target.value); }}
                                                placeholder="Enter Seo Description"></Form.Control>
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="productDescriptionCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Seo Keywords<span>*</span></Form.Label>
                                        </Col>
                                        <Col md={8}>
                                        <Form.Control type="text"
                                                value={seoKeywords}
                                                autoComplete="off"
                                                onChange={e => { setSeoKeywords(e.target.value); }}
                                                placeholder="Enter Seo KeyWord"></Form.Control>
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="my-3" controlId="productMoqCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Upload Default Image</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Col className="col-xl-8 col-md-7">
                                            <input
                                                        className="form-control"
                                                        onChange={handleProductImage}
                                                        type="file"
                                                        required
                                                        />       
                                         </Col>
                                            <Col className="custom-width mx- 5 my-4">
                                                {previewImage && (
                                                    <>
                                                        <Col>
                                                            <Image src={previewImage} width={400} height={300} />
                                                        </Col>
                                                        <Col>
                                                            <Button
                                                                className=" rounded-pill mt-2" variant="danger"
                                                                aria-label="Close"
                                                                onClick={clearPreviewImage}
                                                            >
                                                                <span className="" aria-hidden="true">
                                                                    Remove
                                                                </span>
                                                            </Button>
                                                        </Col>
                                                    </>
                                                )}
                                            </Col>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group>
                                    <Row className="justify-content-end">
                                        <Col className="text-end">
                                            <Button type="button" variant="danger" className="my-4 mx-2" onClick={goBack} >
                                                Cancel
                                            </Button>
                                            {/* </Col>
                                        <Col md={4}> */}
                                            <Button type="button" className="btn btn-primary my-4 mx-2"
                                                onClick={submitBasicInfoFormData}
                                            >Next</Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Form>
                        </Row>
                    </Container>
                </Col>

            </Row>
        </Container>

    </>
}
export default ProductBasicDetails;