export const saveLoginAction = (personData:any) => {
    return {
        type: 'LOGIN',
        payload: personData
    }
}

export const saveAuthToken = (authToken:any) => {
    return {
        type: 'SAVE_AUTH_TOKEN',
        payload: authToken
    }
}

export const clearLoginAction = () => {
    return {
        type: 'LOGOUT',
        payload: null
    }
}