import { Button, Card, Col, Container, Form, Row } from "react-bootstrap"
import FormHeader from "../../../components/form-header/form-header"
import { LocationPayload } from "../../../data/services/Location-api-service/location-payload";
import { toast } from "react-toastify";
import LocationApiService from "../../../data/services/Location-api-service/location-api-service";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action";

const AddLocation = () => {

    const locationApiservice: LocationApiService = new LocationApiService();
    const [name, setName] = useState('')
    const [code, setCode] = useState('')
    let navigate: any = useNavigate();
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('ADD LOCATION'));


    const saveLocation = async (event: any) => {
        try {
            event.preventDefault();
            if (!name) {
                toast.error("Please Enter name", { containerId: 'TR' });
                return;
            }
            if (!code) {
                toast.error("Please Select Code ", { containerId: 'TR' });
                return;
            }

            let payload: LocationPayload = {
                name: name,
                code: code,
            }
            await locationApiservice.createLocation(payload);
            toast.success('Location Created Successfully', { containerId: 'TR' });
            navigate('/main-layout/location-datatable')
        } catch (error: any) {
            console.log("Country Error")
            toast.error(error.response.data.message, { containerId: 'TR' });
        }
    }

    const goBack = () => {
        navigate('/main-layout/location-datatable');
    }
    return <>

        <Container fluid>
            <Row>
                <Col className="">
                    <Container fluid>
                        <Card>
                            <Row>
                                <Form noValidate className="needs-validation user-add" onSubmit={saveLocation}>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Name</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control

                                                    type="text" value={name}
                                                    autoComplete="off"
                                                    onChange={e => { setName(e.target.value) }}
                                                    required />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="categorySort">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Code</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="text" value={code}
                                                    autoComplete="off"
                                                    onChange={e => { setCode(e.target.value) }}
                                                    required />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Row >
                                            <Col xs={8} className="d-flex justify-content-end">
                                                <Button type="button" variant="danger" className="mx-2" onClick={goBack} >
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" >Save</Button>

                                            </Col>
                                        </Row>

                                    </Form.Group>

                                </Form>
                            </Row>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>
    </>
}
export default AddLocation