const loginReducer = (state = {}, action: {type:any, payload:any}) => {
    switch(action.type){
        case "LOGIN":
            return action.payload;
        case "LOGOUT":
            return null;
        default:
            return state;
    }
}
export default loginReducer;