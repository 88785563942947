import { useDispatch } from "react-redux";
import RoleApiService from "../../../data/services/role/role-api-service";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action";
import { useState } from "react";
import { Container, Row, Col, Card, Button, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddPrevilage from "./add-privilege";
import { RolePayload } from "../../../data/services/role/role-payload";

const AddRole = () => {
    const roleApiService: RoleApiService = new RoleApiService();
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('ADD ROLE'));
    const [name, setName] = useState('')
    const [code, setCode] = useState('')
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    let navigate: any = useNavigate()

    const openPrivilege = () => {
        handleShow();
    }

    const handleClose = () => {
        setShow(false);
    }
    const goBack = () => {
        navigate('/main-layout/role-datatable');
    }

    const saveRole = async (event: any) => {
        try {
            event.preventDefault();
            if (!name) {
                toast.error("Please Enter name", { containerId: 'TR' });
                return;
            }
            if (!code) {
                toast.error("Please Select  Code ", { containerId: 'TR' });
                return;
            }

            let payload: RolePayload = {
                name: name,
                roleCode: code,
                rolePrivileges: ''
            }
            await roleApiService.createRole(payload);
            toast.success('Role Created Successfully', { containerId: 'TR' });
            navigate('/main-layout/role-datatable')
        } catch (error: any) {
            console.log("Country Error")
            toast.error(error.response.data.message, { containerId: 'TR' });
        }
    }

    return <>

        <Container fluid>
            <Row>
                <Col className="">
                    <Container fluid>
                        <Card>
                            <Row>
                                <Form noValidate className="needs-validation user-add" onSubmit={saveRole}>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Role</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="text" value={name}
                                                    autoComplete="off"
                                                    onChange={e => { setName(e.target.value) }}
                                                    required />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="categorySort">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Role Code</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="text"
                                                    value={code}
                                                    autoComplete="off"
                                                    onChange={e => { setCode(e.target.value) }}
                                                    required />
                                            </Col>

                                        </Row>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="categorySort">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Privilege</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Button type="button" onClick={openPrivilege}
                                                    className="btn btn-primary add-row mt-md-0 mt-2">
                                                    <FontAwesomeIcon icon={faCirclePlus} />
                                                    &nbsp;Add New
                                                </Button>
                                            </Col>

                                        </Row>
                                    </Form.Group>

                                    <Form.Group>
                                        <Row >
                                            <Col xs={8} className="d-flex justify-content-end mt-3">
                                                <Button type="button" variant="danger" className="mx-2" onClick={goBack} >
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" > &nbsp;Save&nbsp;</Button>

                                            </Col>
                                        </Row>

                                    </Form.Group>

                                </Form>
                            </Row>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>

        <Modal show={show} dialogClassName="modal-90w"
            onHide={handleClose} centered
            backdrop="static"
            keyboard={false} size="xl">
            <Modal.Header closeButton>Add Privilege</Modal.Header>
            <Modal.Body>
                <AddPrevilage addPrevilage={openPrivilege} handleClose={handleClose} />
            </Modal.Body>
        </Modal>
    </>
}
export default AddRole
