import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import axios, { AxiosError } from 'axios';
import { useDispatch } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action";
import { confirmAlert } from "react-confirm-alert";
import AccountApiService from "../../../data/services/account/account-api-service";


const EditAccount = () => {
    const accountApiService: AccountApiService = new AccountApiService();
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('EDIT ACCOUNT'));
    const [userName, setUserName] = useState<any | null>(null)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [mobile, setMobile] = useState('')
    const [roleCode, setRoleCode] = useState('')
    const [password, setPassword] = useState('')
    const [roleData, setRoleData] = useState<any>([]);
    const { state } = useLocation();
    const id = state.id

    let navigate: any = useNavigate()
    const getAccountById = async () => {
        try {
            let data = await accountApiService.getAccountById(id);
            accountData(data);
            console.log("getAccountById", data)
        } catch (error) {
            console.log("getAccountById Error");
            console.log(error);
        }
    }
    const goBack = () => {
        navigate('/main-layout/account-datatable');
    }

    const handleChange = (code: any) => {
        setRoleCode(code);
    };

    const handleContactNumberChange = (event: any) => {
        const inputValue = event.target.value;
        const cleanedValue = inputValue.replace(/\D/g, '').slice(0, 20);
        setMobile(cleanedValue);
    };

    const updateAccount = async () => {
        try {
            const payload: any = {
                userName: userName,
                firstName: firstName,
                lastName: lastName,
                roleCode: roleCode,
                mobile: mobile
            }
            await accountApiService.updateAccount(id,payload);
            toast.success('Account Edited Successfully', { containerId: 'TR' });
            navigate('/main-layout/account-datatable')
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    };

    const accountData = (data: any) => {
        setUserName(data.username);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setMobile(data.mobile);
        setRoleCode(data.roleCode);
        setPassword(data.password);
        setRoleCode(data.role.roleCode);
    }

    const validateAccount = async (event: any) => {
        event.preventDefault();

        if (!userName) {
            toast.error("Please Enter UserName", { containerId: 'TR' });
            return;
        }
        if (!firstName) {
            toast.error("Please Enter FirstName", { containerId: 'TR' });
            return;
        }
        if (!lastName) {
            toast.error("Please Enter LastName", { containerId: 'TR' });
            return;
        }
        if (!roleCode) {
            toast.error("Please Enter RoleCOde", { containerId: 'TR' });
            return;
        }

        await updateAccount();
    }

    const fetchTemplateData = async () => {
        try {
            let response: any = await accountApiService.templateData();
            setRoleData(response);
        } catch (error: any) {
            console.log("template error");
            toast.error(error.response.data.message, { containerId: 'TR' });
        }
    }

    const handleSendPassword = async () => {
        try{
          await accountApiService.sendPassword(userName);
          toast.success('Password Sent Successfully', { containerId: 'TR' });

        }catch(error: any){
            console.log("template error");
            toast.error(error?.response?.data?.message, { containerId: 'TR' });
        }
    }

    const openAlert = () => {
        confirmAlert({
            title: "Alert Message",
            message: " Are you sure want to Send Mail?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => handleSendPassword(),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };

    useEffect(() => {
        getAccountById();
        fetchTemplateData();
    }, []);

    return <>
        <Container fluid>
            <Row>
                <Col className="">
                    <Container fluid>
                        <Card>
                            <Row>
                                <Form noValidate className="needs-validation user-add" onSubmit={validateAccount}>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>First Name</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="text" value={firstName}
                                                    autoComplete="off"
                                                    onChange={e => { setFirstName(e.target.value) }}
                                                    required />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="categorySort">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Last Name</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="text"
                                                    value={lastName}
                                                    autoComplete="off"
                                                    onChange={e => { setLastName(e.target.value) }}
                                                    required />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>email</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="email"
                                                    value={userName}
                                                    onChange={(event) => setUserName(event.target.value)}
                                                />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Password</Form.Label>
                                            </Col>
                                            <Col md={3}>
                                            <Button onClick={() => openAlert()} variant="primary" >&nbsp;Reset Password</Button>
                                            </Col>
                                            <Col md={5}> Note: Default password will send to <b>{userName}</b>this mail</Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Row></Row>
                                    </Form.Group>
                                    {/* <Form.Group className="mb-3" controlId="categorySort">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>password</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="password"
                                                    value={password}
                                                    autoComplete="new-password"
                                                    onChange={e => { setPassword(e.target.value) }}
                                                    required />
                                            </Col>

                                        </Row>
                                    </Form.Group> */}
                                    <Form.Group className="mb-3" controlId="categorySort">
                                        <Row>
                                            <Col md={2}>
                                                <Form.Label>Mobile</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="number"
                                                    value={mobile}
                                                    placeholder="Enter Your Number"
                                                    onChange={handleContactNumberChange}
                                                    maxLength={10}
                                                    required
                                                    autoComplete="off"
                                                />
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                        <Row>
                                            <Col md={2}>

                                                <Form.Label>Role</Form.Label>
                                            </Col>
                                            <Col md={6}>


                                                <select className="form-control" value={roleCode} onChange={(e) => { handleChange(e.target.value) }} >
                                                    <option value="">Select Role</option>
                                                    {roleData.map((data: any, idx: any) => (
                                                        <option key={idx} value={data.roleCode}>{data.name}</option>
                                                    ))}
                                                </select>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Row >
                                            <Col xs={8} className="d-flex justify-content-end">
                                                <Button type="button" variant="danger" className="secondary mx-2" onClick={goBack} >
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" >&nbsp;Update</Button>

                                            </Col>
                                        </Row>

                                    </Form.Group>

                                </Form>
                            </Row>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>
    </>
}
export default EditAccount