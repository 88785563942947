import { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
import ProductApiService from "../../../../data/services/product/product-api-service";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ProductTag from "../product-tag/product-tag";
import EditProductTag from "./edit-product-tag";
import { faEye, faArrowUpFromBracket, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteProductGallery from "../delete-product-gallery";
import ProductGallery from "../product-gallery";

const EditProductBasicDetails = (props: any) => {

    const productApiService: ProductApiService = new ProductApiService();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('')
    const [moq, setMoq] = useState('')
    const [sku, setSku] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [defaultImage, setDefaultImage] = useState<any>();
    const [previewImage, setPreviewImage] = useState<any>('');
    const [categoryData, setCategoryData] = useState<any>([]);
    const [selectedCategory, setSelectedCategory] = useState<any>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [selectedProductType, setselectedProductType] = useState('');
    const [productTypeData, setProductTypeData] = useState<any>([]);
    const [isChecked, setIsChecked] = useState(false);
    const [seoTitle, setSeoTitle] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [seoKeywords, setSeoKeywords] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleShow = () => setShowConfirmation(true);
    const handleClose = () => setShowConfirmation(false);
    const handleGalleryImgClose = () => setShowImageGallery(false);

    const navigate: any = useNavigate();

    const [showImageGallery, setShowImageGallery] = useState(false);
    const [showViewImgGallery, setShowViewImgGallery] = useState(false);

    const handleViewGalleryImgClose = () => {
        setShowViewImgGallery(false);
    }

    const viewImgGallery = () => {
        setShowViewImgGallery(true);
    }

    const addImageGallery = () => {
        setShowImageGallery(true);
        setShowViewImgGallery(false);
    }
    interface Tag {
        code: string;
        value: string;
        checked: boolean;
    }
    const [tags, setTags] = useState<any>([
        { code: 'new_arrival', value: 'New Arrival', checked: false },
        { code: 'trending', value: 'Trending', checked: false },
        { code: 'special_product', value: 'Special Product', checked: false }
    ]);

    const handleProductImage = (e: any) => {
        let file: any = e.target.files[0];
        if (file) {
                console.log('Selected SVG file:', file);
                let previewImage: any = URL.createObjectURL(file);
                setPreviewImage(previewImage);
                setDefaultImage(file);
        }
    }

    const getProductTemplate = async () => {
        try {
            let data = await productApiService.getAllProductTemplate();
            setCategoryData(data.categories);
            setProductTypeData(data.productTypeEnumValues);
        } catch (error) {
            console.log(error);
        }
    }

    const validateBasicInfoFormData = (): any => {
        if (!selectedProductType) {
            throw new Error("Product Type Should Not Be Empty");
        }
        if (!name) {
            throw new Error("Name Should Not Be Empty");
        }
        if (!selectedCategory) {
            throw new Error("Category Should Not Be Empty");
        }
        if (!moq) {
            throw new Error("MOQ Should Not Be Empty");
        }
        if (!sku) {
            throw new Error("SKU Should Not Be Empty");
        }

    }

    const handleMOQChange = (e: any) => {
        const inputValue = e.target.value;
        if (/^[1-9]\d*$/.test(inputValue) || inputValue === '') {
            setMoq(inputValue);
        }
    };

    const constructCategory = () => {
        const categories: any[] = [];
        for (let i = 0; i < categoryData.length; i++) {
            let category: any = {};
            if (categoryData[i].id == selectedCategoryId) {
                category = categoryData[i].id;
                categories.push(category);
            }
        }
        return categories;
    }
    const validateAndSaveBasicInfoFormData = () => {
        try {

            validateBasicInfoFormData();
            if (props.productFormData.has('productType')) {
                props.productFormData.delete('productType');
            }
            props.productFormData.append('productType', selectedProductType);
            if (selectedProductType == 'NORMAL') {
                if (props.productFormData.has('baseProductId')) {
                    props.productFormData.delete('baseProductId');
                }
                if (props.productFormData.has('variants')) {
                    props.productFormData.delete('variants');
                }
            }

            if (props.productFormData.has('name')) {
                props.productFormData.delete('name');
            }
            props.productFormData.append('name', name);

            if (props.productFormData.has('category')) {
                props.productFormData.delete('category');
            }
            props.productFormData.append('category', JSON.stringify(constructCategory()));

            if (props.productFormData.has('description')) {
                props.productFormData.delete('description');
            }
            props.productFormData.append('description', description);

            if (props.productFormData.has('videoUrl')) {
                props.productFormData.delete('videoUrl');
            }
            props.productFormData.append('videoUrl', videoUrl);

            if (props.productFormData.has('searchKeyword')) {
                props.productFormData.delete('searchKeyword');
            }
            props.productFormData.append('searchKeyword', searchKeyword);

            if (props.productFormData.has('moq')) {
                props.productFormData.delete('moq');
            }
            props.productFormData.append('moq', moq);

            if (props.productFormData.has('sku')) {
                props.productFormData.delete('sku');
            }
            props.productFormData.append('sku', sku);

            if (props.productFormData.has('sortOrder')) {
                props.productFormData.delete('sortOrder');
            }
            // props.productFormData.append('sortOrder', sortOrder);

            if (props.productFormData.has('file')) {
                props.productFormData.delete('file');
            }
            if (defaultImage) {
                props.productFormData.append('file', defaultImage);
            }
            if (props.productFormData.has('tags')) {
                props.productFormData.delete('tags');
            }
            let selectedTags: any[] = [];
            for (let i = 0; i < tags.length; i++) {
                if (tags[i].checked) {
                    selectedTags.push(tags[i].code);
                }
            }
            if (selectedTags.length > 0) {
                console.log(JSON.stringify(selectedTags));
                props.productFormData.append('tags', JSON.stringify(selectedTags));
            }

            if (props.productFormData.has('seoTitle')) {
                props.productFormData.delete('seoTitle');
            }
            props.productFormData.append('seoTitle', seoTitle);

            if (props.productFormData.has('seoDescription')) {
                props.productFormData.delete('seoDescription');
            }
            props.productFormData.append('seoDescription', seoDescription);

            if (props.productFormData.has('seoKeywords')) {
                props.productFormData.delete('seoKeywords');
            }
            props.productFormData.append('seoKeywords', seoKeywords);
        } catch (error: any) {
            throw error;
        }
    }

    const handleTagSelectionChange = (e: any, idx: any, item: any) => {
        item.checked = e.target.checked;
        tags[idx] = item;
        setTags([...tags]);
    };

    const submitBasicInfoFormData = () => {
        try {
            validateAndSaveBasicInfoFormData();
            props.onNextCallback();
        } catch (error: any) {
            console.log(error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const highlightEditProductData = (data: any) => {
        try {
            setName(data.name);
            setDescription(data.description);
            setSearchKeyword(data.searchKeyword);
            setMoq(data.moq);
            setSku(data.sku);
            setSortOrder(data.sortOrder);
            setVideoUrl(data.videoUrl);
            setPreviewImage(data.svgImgUrl);
            setSeoDescription(data.seoDescription);
            setSeoKeywords(data.seoKeywords);
            setSeoTitle(data.seoTitle);
            let categoryData = data.categories;
            if (categoryData) {
                setSelectedCategory(categoryData[0].id);
                setSelectedCategoryId(categoryData[0].id);
            }
            const updatedTags = tags.map((tag: Tag) => {
                const matchingTag = data.tags.find((dataTag: any) => dataTag.tagName === tag.code);
                if (matchingTag) {
                    return { ...tag, checked: true };
                }
                return tag;
            });
            setTags(updatedTags);
            if(data.productType) {
                handleProductTypeChangeListener(data.productType.toUpperCase());
            }
        } catch (error: any) {
            throw (error);
        }

    }
    const handleCategoryChange = (event: any) => {
        const categoryId = event.target.value;
        setSelectedCategoryId(categoryId.trim());
        setSelectedCategory(categoryId);
    };

    const getProductById = async () => {
        try {
            let data = await productApiService.getProductById(props.productId);
            highlightEditProductData(data);
            console.log("getProductById", data)
        } catch (error) {
            console.log("getProductById Error");
            console.log(error);
        }
    }

    const clearPreviewImage = () => {
        setPreviewImage(null);
    }
    const deleteDefaultImage = async (id: any) => {
        setPreviewImage(null);
        try {
            let data = await productApiService.deleteDefaultImageById(id);
            console.log("deleteDefaultImage", data);
            toast.success('Default Image Deleted Successfully', { containerId: 'TR' });
        } catch (error) {
            console.log("deleteDefaultImage Error");
            console.log(error);
        }
    }
    const handleSwitchChange = () => {
        setIsChecked(!isChecked);
    };

    const handleProductTypeChange = (e: any) => {
        e.preventDefault();
        const selectedValue = e.target.value;
        handleProductTypeChangeListener(selectedValue);
    };

    const handleProductTypeChangeListener = (selectedValue: string) => {
        setselectedProductType(selectedValue);
        props.onProductTypeChangeListener(selectedValue);
    }

    useEffect(() => {
        getProductTemplate();
        getProductById();
    }, [props.data, props.productId]);

    const goBack = () => {
        if (props.handleClose) {
            props.handleClose();
        } else {
            navigate('/main-layout/product-container');
        }
    }


    const openAlert = () => {
        handleShow();
    };

    const handleConfirm = () => {
        deleteDefaultImage(props.productId);
        setShowConfirmation(false);
    };


    return <>
        <Modal show={showConfirmation} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Alert Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure want to Delete ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" size="sm" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" size="sm" onClick={handleConfirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
        <Container >

            <Row className="mt-5">
                <Col className="">
                    <Container fluid>
                        <Row>
                            <Form noValidate>
                                <Form.Group className="mb-3" controlId="productNameCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Name<span>*</span></Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control
                                                disabled={props.write}
                                                type="text"
                                                value={name}
                                                onChange={e => { setName(e.target.value) }}
                                                placeholder="Enter product Name" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mt-3" controlId="productCategoryCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Select Category<span>*</span></Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control
                                                disabled={props.write}
                                                as="select"
                                                className="mb-3"
                                                onChange={(e) => { handleCategoryChange(e) }}
                                                value={selectedCategoryId}
                                            >
                                                <option value="">--Select--</option>
                                                {categoryData && categoryData.map((data: any, idx: any) => (
                                                    <option key={idx} value={data.id}>{data.name}</option>
                                                ))}
                                            </Form.Control>

                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="needs-validation" controlId="productCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Select Product Type <span>*</span></Form.Label>
                                        </Col>
                                        <Col md={8}>

                                            <select className="form-control" onChange={handleProductTypeChange}
                                                disabled={props.write}
                                                value={selectedProductType}>
                                                {productTypeData.length > 0 && productTypeData.map((item: any, idx: any) => (
                                                    <option key={idx} value={item.code}>{item.value}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="productDescriptionCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Description</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control as="textarea" rows={6}
                                                disabled={props.write}
                                                value={description}
                                                maxLength={600}
                                                onChange={e => { setDescription(e.target.value) }}
                                                placeholder="Enter Description (600 max)"></Form.Control>
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="productMoqCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>MOQ<span>*</span></Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control
                                                disabled={props.write}
                                                type="text"
                                                value={moq}
                                                onChange={handleMOQChange}
                                                placeholder="Enter MOQ"></Form.Control>
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="productMoqCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>SKU<span>*</span></Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control
                                                disabled={props.write}
                                                type="text"
                                                value={sku}
                                                onChange={e => { setSku(e.target.value) }}
                                                placeholder="Enter SKU"></Form.Control>
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="my-3" controlId="productSearchKeywordCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Search Keyword</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control
                                                disabled={props.write}
                                                type="text"
                                                value={searchKeyword}
                                                onChange={e => { setSearchKeyword(e.target.value) }}
                                                placeholder="Enter Search KeyWord"></Form.Control>
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="needs-validation" controlId="productCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Tags</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Row>
                                                {
                                                    tags.length > 0 && tags.map((item: any, idx: any) => (
                                                        <Form.Check
                                                            disabled={props.write}
                                                            inline
                                                            onChange={(e) => handleTagSelectionChange(e, idx, item)}
                                                            checked={item.checked}
                                                            type='checkbox'
                                                            name="group1"
                                                            label={`${item.value}`}
                                                        />
                                                    ))
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="productDescriptionCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Seo Title<span>*</span></Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={seoTitle}
                                                autoComplete="off"
                                                onChange={e => { setSeoTitle(e.target.value); }}
                                                placeholder="Enter Seo Title"></Form.Control>
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="productDescriptionCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Seo Description<span>*</span></Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={seoDescription}
                                                autoComplete="off"
                                                onChange={e => { setSeoDescription(e.target.value); }}
                                                placeholder="Enter Seo Description"></Form.Control>
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="productDescriptionCtrlId">
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label>Seo Keywords<span>*</span></Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control type="text"
                                                value={seoKeywords}
                                                autoComplete="off"
                                                onChange={e => { setSeoKeywords(e.target.value); }}
                                                placeholder="Enter Seo KeyWord"></Form.Control>
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="productMoqCtrlId">
                                   {!props.write && <Row>
                                        <Col md={3}>
                                            <Form.Label>Upload Default Image</Form.Label>
                                        </Col>
                                        <Col md={8}>
                                            <Col className="col-xl-8 col-md-7">
                                                <input
                                                    className="form-control"
                                                    onChange={e => { handleProductImage(e) }}
                                                    type="file"
                                                    required
                                                />
                                            </Col>
                                        </Col>
                                    </Row>}
                                    <Row>
                                        <Col md={3}></Col>
                                        <Col md={6} className=" mx-5 my-4">
                                            {previewImage && (
                                                <>
                                                    <img src={previewImage} width={400} height={300} />

                                                </>
                                            )}
                                        </Col>
                                        <Col className="mt-3">
                                            {previewImage && !props.write && <div className="image-overlay">
                                                <FontAwesomeIcon
                                                    icon={faXmark}
                                                    size="2x"
                                                    textAnchor="Remove"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => openAlert()}
                                                />
                                            </div>}
                                        </Col>
                                    </Row>

                                </Form.Group>
                                {!props.write &&
                                    <Form.Group controlId="productMoqCtrlId">
                                        <Row>
                                            <Col md={3} className="mt-4" >
                                                <Form.Label>View Gallery</Form.Label>
                                            </Col>
                                            <Col md={8} className=" ">
                                                <Button type="button" className="btn btn-primary my-4"
                                                    onClick={viewImgGallery}
                                                >
                                                    <FontAwesomeIcon icon={faEye} /> &nbsp;View Gallery</Button>
                                            </Col>
                                        </Row>
                                    </Form.Group>}

                                <Form.Group>
                                    <Row className="justify-content-end">
                                        <Col className="text-end">
                                            <Button type="button" variant="danger" className="my-4 mx-2" onClick={goBack} >
                                                Cancel
                                            </Button>
                                            <Button type="button" className="btn btn-primary my-4"
                                                onClick={submitBasicInfoFormData}
                                            >Next</Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Form>
                        </Row>
                    </Container>
                </Col>

            </Row>
        </Container>

        <Modal show={showViewImgGallery} dialogClassName="modal-90w"
            onHide={handleViewGalleryImgClose}
            backdrop="static"
            keyboard={false} size="xl">
            <Modal.Header closeButton>
                <Col md={4}> View Gallery</Col>
                <Col md={5}></Col>
                <Col md={2} className="text-end">
                    <Button type="button" className="btn btn-primary"
                        onClick={addImageGallery}
                    >      <FontAwesomeIcon icon={faArrowUpFromBracket} /> &nbsp;

                        Upload
                    </Button>
                </Col>
            </Modal.Header>
            <Modal.Body>
                <DeleteProductGallery productId={props.productId} handleClose={handleViewGalleryImgClose} />
            </Modal.Body>
        </Modal>

        <Modal show={showImageGallery} dialogClassName="modal-90w"
            onHide={handleGalleryImgClose}
            backdrop="static"
            keyboard={false} size="xl">
            <Modal.Header closeButton> Upload Image</Modal.Header>
            <Modal.Body>
                <ProductGallery productId={props.productId} handleClose={handleGalleryImgClose} />
            </Modal.Body>
        </Modal>

    </>
}
export default EditProductBasicDetails;