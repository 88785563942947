import axios, { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FeatureApiService from "../../../data/services/feature/feature-api-service";
import RoleApiService from "../../../data/services/role/role-api-service";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action";
import RolePrivilegeApiService from "../../../data/services/role-privilege-api-service/role-privilege-api-service";

const data = [
    {
        name: "Categories",
        visibility: false,
        subMenu: []
    },
    {
        name: "Product Catalogue",
        visibility: false,
        subMenu: [

            {
                name: "Product",
                visibility: false
            },
            {
                name: "Base Product",
                visibility: false
            }

        ]
    },

    {
        name: "Configuration",
        visibility: false,
        subMenu: [

            {
                name: "Variants",
                visibility: false
            },
            {
                name: "Attributes",
                visibility: false
            },

            {
                name: "Locations",
                visibility: false
            },
            {
                name: "Email Configuration",
                visibility: false
            }
        ]
    },
    {
        name: "Users",
        visibility: false,
        subMenu: [],
    },
    {
        name: "System",
        visibility: false,
        subMenu: [
            {
                name: "Privilege",
                visibility: false
            },
            {
                name: "Permission",
                visibility: false
            },
        ]
    },
    {
        name: "Reports",
        visibility: false,
        subMenu: [
            {
                name: "Request for Quotes",
                visibility: false
            },
            {
                name: "News Letter Subscription",
                visibility: false
            }
        ]
    },
]

const ViewPrivilege = () => {
    const featureApiService: FeatureApiService = new FeatureApiService();
    const roleApiService: RoleApiService = new RoleApiService();
    const rolePrivilegeApiService: RolePrivilegeApiService = new RolePrivilegeApiService();

    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('ADD ROLE PRIVILEGE'));
    const [roleData, setRoleData] = useState<any>([]);
    const [featureData, setFeatureData] = useState<any>([]);
    const [roleId, setRoleId] = useState('');
    const [roleName, setRoleName] = useState ('');

    let navigate: any = useNavigate()

    const handleRoleChange = (id: any) => {
        setRoleId(id);
    }

    const handleFeatureVisibilityChange = (idx: any, checked: any) => {
        const updatedFeatureData = [...featureData];
        updatedFeatureData[idx].checked = checked;
        if (updatedFeatureData[idx].subFeatures.length != 0) {
            for (let i = 0; i < updatedFeatureData[idx].subFeatures.length; i++) {
                updatedFeatureData[idx].subFeatures[i].checked = checked;
            }
        }
        setFeatureData([...updatedFeatureData]);
    }

    const handleSubMenuVisibilityChange = (parentIdx: any, subIdx: any, checked: any) => {
        const updatedFeatureData = [...featureData];
        updatedFeatureData[parentIdx].subFeatures[subIdx].checked = checked;
        setFeatureData([...updatedFeatureData]);
    }
    const constructCheckedRow = (result: any) => {
        for (let i = 0; i < result.length; i++) {
            result[i]['checked'] = true;
            if (result[i].subFeatures.lenght != 0) {
                for (let j = 0; j < result[i].subFeatures.length; j++) {
                    result[i].subFeatures[j].checked = true;
                }
            }
        }
        setFeatureData(result);

    }

    const getFeatureData = async () => {
        try {
            let result = await featureApiService.getAllFeatureTreeView();
            console.log("getFeatureData", result);
            constructCheckedRow(result);
        } catch (error) {
            console.log("getFeatureData Error");
            console.log(error);
        }
    }
    const getRoleData = async () => {
        try {
            let result = await roleApiService.getAllRole();
            console.log("getRoleData", result);
            setRoleData(result);
        } catch (error) {
            console.log("getRoleData Error");
            console.log(error);
        }
    }
    const constructPrivilegePayload = (featureData: any[]) => {
        const features: any[] = [];
        for (let i = 0; i < featureData.length; i++) {
            const feature: any = {};
            feature.featureId = featureData[i].id;
            feature.checked = featureData[i].checked;
            feature.parentId = ''
            feature.subFeatures = constructPrivilegeSubMenuPayload(featureData[i].subFeatures, featureData[i].id);

            // Include the parent feature if any child is checked
            if (feature.checked && feature.subFeatures.length == 0) {
                features.push(feature);
            }
            if (feature.subFeatures.some((subFeature: any) => subFeature.checked)) {
                features.push(feature);
            }
        }
        return features;
    };



    const constructPrivilegeSubMenuPayload = (featureSubMenuData: any[], parentId: any) => {
        const subFeatures: any[] = [];
        for (let i = 0; i < featureSubMenuData.length; i++) {
            if (featureSubMenuData[i].checked) {
                const subFeature: any = {};
                subFeature.featureId = featureSubMenuData[i].id;
                subFeature.checked = featureSubMenuData[i].checked;
                subFeature.parentId = parentId;
                subFeatures.push(subFeature);
            }
        }
        return subFeatures;
    };

    const saveRolePermission = async (event: any) => {
        try {
            event.preventDefault();
            if (!roleName) {
                toast.error("Please Enter Role Name", { containerId: 'TR' });
                return;
            }
            let payload: any;
            payload = constructPrivilegePayload(featureData);
            console.log("Payload:", payload);
            await rolePrivilegeApiService.createRolePrivilege(roleName, payload);
            toast.success('Role Permission Created Successfully', { containerId: 'TR' });
            navigate('/main-layout/privilege-datatable');
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log("Privilege Create Failed", error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }
    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    };
    const goBack = () => {
        navigate('/main-layout/privilege-datatable');
    }

    useEffect(() => {
        getRoleData();
        getFeatureData();
    }, [])
    return <>
        <Container fluid>
            <Row>
                <Col className="">
                    <Container fluid>
                        <Card>
                            <Row>
                                <Form noValidate className="needs-validation user-add">
                                    <Form.Group className="mb-3" controlId="productNameCtrlId">
                                    <Row>
                                            <Col md={2}>
                                                <Form.Label>Role</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Control
                                                    type="text" value={roleName}
                                                    autoComplete="off"
                                                    onChange={e => { setRoleName(e.target.value) }}
                                                    required />
                                            </Col>

                                        </Row>
                                    </Form.Group>

                                    <Row className="feature-data">
                                        <h3>Feature Name</h3>
                                        {featureData &&
                                            featureData.map((item: any, idx: any) => (
                                                <Form.Group className="my-1 p-2" key={idx}>
                                                    <Row>
                                                        <Form.Check
                                                            type="checkbox"
                                                            label={item.name}
                                                            checked={item.checked}
                                                            onChange={(e) => handleFeatureVisibilityChange(idx, e.target.checked)}
                                                        />
                                                    </Row>
                                                    {item.subFeatures.map((subItem: any, subIdx: any) => (
                                                        <Row className="ms-3" key={subIdx}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                label={subItem.name}
                                                                checked={subItem.checked}
                                                                onChange={(e) => handleSubMenuVisibilityChange(idx, subIdx, e.target.checked)}
                                                            />
                                                        </Row>
                                                    ))}
                                                </Form.Group>
                                            ))}
                                    </Row>

                                    <Form.Group>
                                        <Row >
                                            <Col xs={8} className="d-flex justify-content-end mt-3">
                                                <Button type="button" variant="danger" className="mx-2" onClick={goBack} >
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" onClick={saveRolePermission}> &nbsp;Save Changes&nbsp;</Button>

                                            </Col>
                                        </Row>

                                    </Form.Group>
                                </Form>
                            </Row>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>
    </>
}
export default ViewPrivilege