import { useDispatch } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import FeatureApiService from "../../../data/services/feature/feature-api-service";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { Button, Card, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { faCirclePlus, faEye, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FeatureDatatable = () => {
    const featureApiService: FeatureApiService = new FeatureApiService();
    const [featureData, setFeatureData] = useState<any>([]);
    const [featureActions, setFeatureActions] = useState([])
    let navigate: any = useNavigate();
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('FEATURE'));
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (data:any) => {
        setFeatureActions(data.featureActions)
        setShow(true);
    }

    const getFeatureData = async () => {
        try {
            let result = await featureApiService.getAllFeature();
            console.log("getFeatureData", result);
            setFeatureData(result);
        } catch (error) {
            console.log("getFeatureData Error");
            console.log(error);
        }
    }
    const updateFeature = (id: any) => {
        navigate('/main-layout/edit-feature', { state: { id: id } });
    }

    const addFeature = () => {
        navigate('/main-layout/add-feature')
    }
    const deleteFeature = async (id: any) => {
        console.log('Feature Id=' + id);
        try {

            await featureApiService.deleteFeature(id);
            toast.success('Feature Deleted Successfully', { containerId: 'TR' });
            await getFeatureData();
            navigate('/main-layout/feature-datatable');
            return;

        } catch (error) {
            console.log("ERROR occured deleteFeature() error=== " + JSON.stringify(error));
        }
    }
    const openAlert = (data: any) => {
        confirmAlert({
            title: "Alert Message",
            message: " Are you sure want to Delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteFeature(data),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };


    useEffect(() => {
        getFeatureData();
    }, []);


    return <>
        {/* <FormHeader title="Feature Datatable" /> */}
        <Container fluid>
            <Row className="row">
                <Col className="col-sm-12">
                    <Card className="card">
                        <Col className="card-header">
                            <Button type="button" onClick={addFeature}
                                className="btn btn-primary add-row mt-md-0 mt-2">
                                <FontAwesomeIcon icon={faCirclePlus} />
                                &nbsp;Add New
                            </Button>

                        </Col>

                        <Card.Body className="card-body p-4">
                            <Col className="table-responsive table-desi">
                                <Table responsive className=' all-package table-category' striped variant="white">
                                    <thead >
                                        <tr>
                                            <th>Name</th>
                                            <th>Code</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {featureData.map((data: any, idx: any) => (
                                            <tr key={idx}>

                                                <td data-field="name">{data.name}</td>
                                                <td data-field="name">{data.featureCode}</td>
                                                <td>
                                                    <a onClick={() => handleShow(data)}>
                                                        <FontAwesomeIcon icon={faEye} title="Feature Actions" className='me-2 mx-2 text-primary' />
                                                    </a>
                                                    <a onClick={() => updateFeature(data.id)}>
                                                        <FontAwesomeIcon icon={faPenToSquare} title="Edit Feature Type" className='me-2 mx-2 font-success' />
                                                    </a>
                                                    <a href="javascript:void(0)" onClick={() => openAlert(data.id)} className="cursor-pointer">
                                                        <FontAwesomeIcon icon={faTrash} title="Delete Feature" className='me-2 mx-2 delete-btn' />
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

        <Modal show={show} onHide={handleClose}
            backdrop="static"
            keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Feature Actions</Modal.Title>
            </Modal.Header>
            <Modal.Body>{featureActions.map((featureActionData: any, idx: any) => (
                <Col>
                    <Form.Label><b>Action Name :</b> {featureActionData.actionName}</Form.Label><br />
                    <Form.Label><b>Action Code :</b> {featureActionData.actionCode}</Form.Label>
                    <hr />
                </Col>
            ))}</Modal.Body>
        </Modal>
    </>
}
export default FeatureDatatable