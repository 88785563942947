import HttpClientWrapper from "../../api/http-client-wrapper";

class VariantApiService {

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getAllVariant = async() => {
        try{
            let data:any= await this.httpClientWrapper.get('/v1/variants' );
            console.log("VariantApiService getAllVariant() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    getAllVariantType = async() => {
        try{
            let data:any= await this.httpClientWrapper.get('/v1/variantTypes' );
            console.log("VariantApiService getAllVariantType() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }
    getVariantByvariantTypeId = async(id:any) => {
        try{
            let data:any= await this.httpClientWrapper.get('/v1/variants/fetchByvariantTypeId?variantTypeId='+id );
            console.log("VariantApiService getVariantById() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    getVariantById = async(id:any) => {
        try{
            let data:any= await this.httpClientWrapper.get('/v1/variants/'+id );
            console.log("VariantApiService getVariantById() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    createVariant = async(payload:any) => {
        try{
            let data:any= await this.httpClientWrapper.post('/v1/variants',payload );
            console.log("VariantApiService createVariant() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    editVariant = async(id:any, payload:any) => {
        try{
            let data:any= await this.httpClientWrapper.put('/v1/variants/'+id, payload );
            console.log("VariantApiService editVariant() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    deleteVariant = async(id:any) => {
        try{
            let data:any= await this.httpClientWrapper.delete('/v1/variants/'+id );
            console.log("VariantApiService deleteVariant() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }
}
export default VariantApiService;