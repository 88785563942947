import { faAngleDown, faAngleRight, faCartShopping, faCircle, faGear, faList } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import '../header.css'
import img1 from './../../../assets/logo.png'
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import LoginState from "../../../data/state/login-state"
import FeatureApiService from "../../../data/services/feature/feature-api-service"
const sideMenu = [
    {
        name: "Categories",
        path: '/main-layout/categories-datatable',
        subMenu: [],
        code: 'CATEGORIES',
        roles: ['ADMIN', 'STANDARD_USER'],
        icon: <FontAwesomeIcon icon={faList} />

    },
    {
        name: "product catalogue",
        path: '',
        code: 'PRODUCT_CATALOGUE',
        roles: ['ADMIN', 'STANDARD_USER'],
        icon: <FontAwesomeIcon icon={faGear} />,
        subMenu: [
            {
                name: "Product",
                path: '/main-layout/product-container',
                code: 'PRODUCT',
                roles: ['ADMIN', 'STANDARD_USER'],
            },
            {
                name: "Base Product",
                path: '/main-layout/base-product-datatable',
                code: 'BASE_PRODUCT',
                roles: ['ADMIN', 'STANDARD_USER']
            }

        ]
    },

    {
        name: "Configuration",
        path: '',
        code: 'CONFIGURATION',
        icon: <FontAwesomeIcon icon={faGear} />,
        roles: ['ADMIN'],
        subMenu: [

            {
                name: "Variants",
                path: '/main-layout/variant-datatable',
                code: 'VARIANTS',
                roles: ['ADMIN']

            },
            {
                name: "Attributes",
                path: '/main-layout/attribute-datatable',
                code: 'ATTRIBUTES',
                roles: ['ADMIN']

            },

            {
                name: "Locations",
                path: '/main-layout/location-datatable',
                code: 'LOCATIONS',
                roles: ['ADMIN']

            },
            {
                name: "Email Configuration",
                path: '/main-layout/email-config-datatable',
                code: 'EMAIL_CONFIGURATION',
                roles: ['ADMIN']
            }
        ]
    },
    {
        name: "Users",
        path: '/main-layout/account-datatable',
        subMenu: [],
        code: 'USERS',
        roles: ['ADMIN'],
        icon: <FontAwesomeIcon icon={faList} />

    },
    {
        name: "Roles & Permissions",
        path: '',
        code: 'SYSTEM',
        icon: <FontAwesomeIcon icon={faGear} />,
        roles: ['ADMIN'],
        subMenu: [
            {
                name: "Role Privilege",
                path: '/main-layout/privilege-datatable',
                code: 'PRIVILEGE',
                roles: ['ADMIN']
            },
            {
                name: "Privilege Permission",
                path: '/main-layout/permission-datatable',
                code: 'PERMISSION',
                roles: ['ADMIN']
            },
        ]
    },
    {
        name: "Reports",
        path: '',
        code: 'REPORTS',
        icon: <FontAwesomeIcon icon={faCartShopping} />,
        roles: ['ADMIN', 'STANDARD_USER'],
        subMenu: [
            {
                name: "Request for Quotes",
                path: '/main-layout/quote-datatable',
                code: 'REQUEST_FOR_QUOTES',
                roles: ['ADMIN'],
            },
            {
                name: "News Letter Subscription",
                path: '/main-layout/news-letter-datatable',
                code: 'NEWS_LETTER',
                roles: ['ADMIN', 'STANDARD_USER']
            }
        ]
    },
];

const Menu = () => {
    const loginReducer: any = useSelector((state: LoginState) => state);
    const navigate: any = useNavigate();
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const [activeSubSubMenu, setActiveSubSubMenu] = useState<number | null>(null);
    const [roleCode, setRoleCode] = useState(loginReducer?.personData?.role?.roleCode);
    const [roleId] = useState(loginReducer?.personData?.role?.id);
    const featureApiService: FeatureApiService = new FeatureApiService();
    const [featureData, setFeatureData] = useState<any>([]);

    const [menus, setMenus] = useState<any>([]);
    const dispatch = useDispatch();

    const toggleSubMenu = (menu: any, index: any) => {
        setActiveSubMenu(index === activeSubMenu ? null : index);
        if (menu.subMenu.length > 0) {
            setActiveSubSubMenu(0);
            navigate(menu.subMenu[0].path);
        } else {
            setActiveSubSubMenu(null);
            navigate(menu.path);
        }
    };

    const toggleSubSubMenu = (subMenu: any, index: any) => {
        setActiveSubSubMenu(index === activeSubSubMenu ? null : index);
        navigate(subMenu.path);
    };

    const checkMenuExist = (menuCode: string, resultMenus:any[]) => {
        for(let i =0;i < resultMenus.length;i++) {
            if(resultMenus[i].feature.featureCode == menuCode) {
                return true;
            }
        }
        return false;
    }

    const getAllMenuBasedOnFeatureCodes = (sideMenu: any[], featureData: any[]) => {
        let menus: any[] = [];
        for (let i = 0; i < sideMenu.length; i++) {
            const isMenuExist:boolean = checkMenuExist(sideMenu[i].code, featureData);
            if(isMenuExist) {
                if(sideMenu[i].subMenu && sideMenu[i].subMenu.length > 0) {
                    const copySubMenus = JSON.parse(JSON.stringify(sideMenu[i].subMenu));
                    sideMenu[i].subMenu = getAllMenuBasedOnFeatureCodes([...copySubMenus], featureData);
                }
                menus.push(sideMenu[i]);
            }
        }
        return menus;
    }

    const getFeatureDataByRoleId = async (sideMenu: any[]) => {
        try {
            let result = await featureApiService.getFeatureByRoleId(roleId);
            console.log(result);
            const menus:any[] = getAllMenuBasedOnFeatureCodes(sideMenu, result);
            setMenus([...menus]);
            console.log("getFeatureData test", result);
        } catch (error) {
            console.log("getFeatureData Error");
            console.log(error);
        }
    }

    useEffect(() => {
        if (roleId) {
            getFeatureDataByRoleId([...sideMenu]);
            // getAllMenusBasedOnRoles([...sideMenu]);
        }
    }, [roleId]);

    return <>

        <div className="page-sidebar">
            <div className="main-header-left d-none d-lg-block">
                <div className="logo-wrapper">
                    <a href="#">
                        <img className="d-none d-lg-block blur-up lazyloaded image-bar"
                            src={img1} alt=""
                        />
                    </a>
                </div>
            </div>
            <div className="sidebar custom-scrollbar">
                <a href="javascript:void(0)" className="sidebar-back d-lg-none d-block"><i className="fa fa-times"
                    aria-hidden="true"></i></a>
                {/* <div className="sidebar-user">
                    <img className="img-60" src={img2} alt="#" />
                    <div>
                        <h6 className="f-14"> {loginReducer?.personData?.person?.name ? loginReducer?.personData?.person?.name : 'JOHN'}</h6>
                        <p>{loginReducer?.personData?.person?.email ? loginReducer?.personData?.person?.email : 'general manager.'}</p>

                    </div>
                </div> */}

                <ul className="sidebar-menu">
                    {menus && menus.map((data: any, idx: any) => (
                        <li key={idx}>
                            <a
                                href="javascript:void(0)"
                                className={`sidebar-header ${idx === activeSubMenu ? 'selected' : ''}`}
                                onClick={() => toggleSubMenu(data, idx)}
                            >
                                {data.icon}
                                <span className="mx-3">{data.name}</span>
                                {data.subMenu.length > 0 && (
                                    <span className="pull-right">
                                        {idx === activeSubMenu ? (
                                            <FontAwesomeIcon icon={faAngleDown} />
                                        ) : (
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        )}
                                    </span>
                                )}
                            </a>
                            {data.subMenu && data.subMenu.length > 0 && (
                                <ul
                                    className="sidebar-submenu"
                                    style={{ display: activeSubMenu === idx ? 'block' : 'none' }}
                                >
                                    {data.subMenu.map((subMenu: any, subIdx: any) => (
                                        <li
                                            key={subIdx}
                                            className={`p-2 ${activeSubSubMenu === subIdx ? 'selected' : ''}`}
                                            onClick={() => toggleSubSubMenu(subMenu, subIdx)}
                                        >
                                            <FontAwesomeIcon icon={faCircle} size="2xs" className="me-2" />
                                            <a href="javascript:void(0)">
                                                <i className="fa fa-circle"></i>
                                                <span>{subMenu.name}</span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </>
}

export default Menu