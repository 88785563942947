import Table from 'react-bootstrap/Table';
import CategoriesApiService from '../../../data/services/category/category-api-service';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faPenToSquare, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios, { AxiosError } from 'axios';
import { confirmAlert } from "react-confirm-alert";
import { hasAccessPrivilege } from '../../../account/helper/access-privilege';
import { UserContext, UserContextData } from '../../../context/user-context';


const CategoryDataTable = () => {
    const categoriesApiService: CategoriesApiService = new CategoriesApiService;
    const [categoriesData, setCategoriesData] = useState<any>([]);
    let navigate: any = useNavigate();
    const userContextData: UserContextData = useContext<UserContextData>({ ...UserContext });
    const [hasEditCategoryPrivilege, setHasEditCategoryPrivilege] = useState(false);
    const [hasDeleteCategoryPrivilege, setHasDeleteCategoryPrivilege] = useState(false);
    const [hasViewCategoryPrivilege, setHasViewCategoryPrivilege] = useState(false);


    const getCategoriesData = async () => {
        try {
            let result = await categoriesApiService.getAllCategories();
            console.log("getCategoriesData", result);
            setCategoriesData(result);
        } catch (error) {
            console.log("getCategoriesData Error");
            console.log(error);
        }
    }


    const updateCategory = (id: any) => {
        navigate('/main-layout/edit-category', { state: { id: id } });
    }

    const deleteCategory = async (data: any) => {
        console.log('VariantType Id=' + data.id);
        try {
            await categoriesApiService.deleteCategory(data.id);
            toast.success('Category Deleted Successfully', { containerId: 'TR' });
            await getCategoriesData();
            return;

        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
            }
            console.log("Categories Create Failed", error);
            toast.error(error, { containerId: 'TR' });
        }

    }
    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const openAlert = (data: any) => {
        confirmAlert({
            title: "Alert Message",
            message: " Are you sure want to Delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteCategory(data),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };

    const checkPermission = () => {
        const editCategoryPrivilege = hasAccessPrivilege(userContextData, "CATEGORIES", "EDIT_CATEGORY");
        setHasEditCategoryPrivilege(editCategoryPrivilege);
        const deleteCategoryPrivilege = hasAccessPrivilege(userContextData, "CATEGORIES", "DELETE_CATEGORY");
        setHasDeleteCategoryPrivilege(deleteCategoryPrivilege);
        const viewCategoryPrivilege = hasAccessPrivilege(userContextData, "CATEGORIES", "VIEW_CATEGORY");
        setHasViewCategoryPrivilege(viewCategoryPrivilege);

    }

    useEffect(() => {
        getCategoriesData();
        checkPermission();
    }, []);


    return <>

        <Col >
            <Table className=' all-package table-category' bordered size="white">
                <thead className='sticky-header' >
                    <tr>
                        <th>Name</th>
                        <th>Sort Order</th>
                        <th>Parent Category</th>
                        {
                            (hasEditCategoryPrivilege || hasDeleteCategoryPrivilege) && <th>Actions</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {categoriesData.map((data: any, idx: any) => (
                        <tr>
                            <td data-field="name">{data.name}</td>
                            <td data-field="name">{data.sortOrder ? data.sortOrder : 'No order'} </td>
                            <td data-field="name">{data.parent ? data.parent.name : 'No Parent Category'} </td>
                            {
                                (hasEditCategoryPrivilege || hasDeleteCategoryPrivilege) && (
                                    <td>
                                        {hasEditCategoryPrivilege &&

                                            <a onClick={() => updateCategory(data.id)} className="cursor-pointer">
                                                <FontAwesomeIcon icon={faPenToSquare} title="edit category" className='me-2 mx-2 font-success' />
                                            </a>
                                        }
                                        {hasDeleteCategoryPrivilege &&
                                            <a onClick={() => openAlert(data)} className="cursor-pointer">
                                                <FontAwesomeIcon icon={faTrash} title="delete category" className='me-2 mx-2 delete-btn' />
                                            </a>
                                        }

                                    </td>
                                )}
                        </tr>
                    ))}
                </tbody>
            </Table>

        </Col>
    </>
}

export default CategoryDataTable 