import HttpClientWrapper from "../../api/http-client-wrapper";

class BannerImageApiService {

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    createBannerImage = async(formData:FormData) => {
        try{
            let data:any= await this.httpClientWrapper.postFormData('/v1/bannerImages',formData );
            console.log("BannerImageApiService createBannerImage() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    getAllBannerImage = async() => {
        try{
            let data:any= await this.httpClientWrapper.get('/v1/bannerImages' );
            console.log("BannerImageApiService getAllBannerImage() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    getBannerImageById = async(id:any) => {
        try{
            let data:any= await this.httpClientWrapper.get('/v1/bannerImages/'+id );
            console.log("BannerImageApiService getBannerImageById() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    
    editBannerImage = async(id:any, formData:FormData) => {
        try{
            let data:any= await this.httpClientWrapper.putFormData('/v1/bannerImages/'+id, formData );
            console.log("BannerImageApiService editBannerImage() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }

    deleteBannerImage = async(id:any) => {
        try{
            let data:any= await this.httpClientWrapper.delete('/v1/bannerImages/'+id );
            console.log("BannerImageApiService deleteBannerImage() response data"+JSON.stringify(data));
            return(data);     
        }catch (error){
            throw error;
        }
    }
}
export default BannerImageApiService;