import { faCirclePlus, faEye, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import RolePermissionApiService from "../../../data/services/permission-api-service/permission-api-service";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action";
import RoleApiService from "../../../data/services/role/role-api-service";
import { UserContext, UserContextData } from "../../../context/user-context";
import { hasAccessPrivilege } from "../../../account/helper/access-privilege";

const PermissionDatatable = () => {
    const rolePermissionApiService: RoleApiService = new RoleApiService();
    const [permissionData, setPermissionData] = useState<any>([]);
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('PERMISSIONS'));
    let navigate: any = useNavigate();
    const userContextData: any = useContext<UserContextData>({ ...UserContext });
    const [hasEditPermissionPrivilege, setHasEditPermissionPrivilege] = useState(true);
    const [hasDeletePermissionPrivilege, setHasDeletePermissionPrivilege] = useState(true);
    const [hasAddPermissionPrivilege, setHasAddPermissionPrivilege] = useState(true);

    const checkPermission = () => {
        const editPermissionPrivilege = hasAccessPrivilege(userContextData, "PERMISSION", "EDIT_PERMISSION");
        setHasEditPermissionPrivilege(editPermissionPrivilege);
        const deletePermissionPrivilege = hasAccessPrivilege(userContextData, "PERMISSION", "DELETE_PERMISSION");
        setHasDeletePermissionPrivilege(deletePermissionPrivilege);
        const addPermissionPrivilege = hasAccessPrivilege(userContextData, "PERMISSION", "ADD_PERMISSION");
        setHasAddPermissionPrivilege(addPermissionPrivilege);

    }

    const addRolePermission = () => {
        navigate('/main-layout/add-role-permission')
    }
    const editPermissionRole = (id: any) => {
        navigate('/main-layout/edit-role-permission', { state: { id: id } })
    }

    const getRolePermissionData = async () => {
        try {
            let result = await rolePermissionApiService.getAllRole();
            console.log("getRolePermissionData", result);
            setPermissionData(result);
        } catch (error) {
            console.log("getRolePermissionData Error");
            console.log(error);
        }
    }

    useEffect(() => {
        getRolePermissionData();
        // checkPermission();
    }, []);
    return <>
        <Container fluid>
            <Row className="row">
                <Col className="col-sm-12">
                    <Card className="card">
                        {/* <Col className="card-header">
                            {hasAddPermissionPrivilege && <Button type="button" onClick={addRolePermission}
                                className="btn btn-primary add-row mt-md-0 mt-2">
                                <FontAwesomeIcon icon={faCirclePlus} />
                                &nbsp;Add Role Permission
                            </Button>
                            }
                        </Col> */}

                        <Card.Body className="card-body p-4">
                            <Col className="table-responsive table-desi">
                                <Table responsive className=' all-package table-category' striped variant="white">
                                    <thead >
                                        <tr>
                                            <th>Role</th>
                                            {
                                                (hasEditPermissionPrivilege || hasDeletePermissionPrivilege) && <th>Actions</th>
                                            }                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userContextData.role.roleCode != 'SUPER_ADMIN' && permissionData.map((data: any, idx: any) => (
                                              data.roleCode != 'SUPER_ADMIN' && (
                                            <tr>
                                                <td data-field="role">{data.name}</td>
                                                <td>
                                                    {hasEditPermissionPrivilege && <a onClick={() => editPermissionRole(data.id)}>
                                                        <FontAwesomeIcon icon={faPenToSquare} title="Edit Role Permission"
                                                            className='me-2 mx-2 font-success' />
                                                    </a>
                                                    }
                                                </td>
                                            </tr>
                                              )
                                        ))}

                                        {userContextData.role.roleCode == 'SUPER_ADMIN' && permissionData.map((data: any, idx: any) => (
                                            <tr>
                                                <td data-field="role">{data.name}</td>
                                                <td>
                                                    {hasEditPermissionPrivilege && <a onClick={() => editPermissionRole(data.id)}>
                                                        <FontAwesomeIcon icon={faPenToSquare} title="Edit Role Permission"
                                                            className='me-2 mx-2 font-success' />
                                                    </a>
                                                    }
                                                </td>
                                            </tr>
                                              
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
}
export default PermissionDatatable