import { Button, Card, Col, Container, Row, Table } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import AttributeApiService from "../../../data/services/attribute-api-service/attribute-api-service";
import { useEffect, useState } from "react";
import { faPenToSquare, faTrash, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { clearFormHeaderAction, saveFormHeaderAction } from "../../../data/state/form-header-action";
import AccountApiService from "../../../data/services/account/account-api-service";

const AccountDatatable = () => {
    const accountApiService: AccountApiService = new AccountApiService();
    const [accountData, setAccountData] = useState<any>([]);
    let navigate: any = useNavigate();
    const dispatch = useDispatch();
    dispatch(clearFormHeaderAction());
    dispatch(saveFormHeaderAction('USERS'));

    const getAccountData = async () => {
        try {
            let result = await accountApiService.getAccount();
            console.log("getAccountData", result);
            setAccountData(result);
        } catch (error) {
            console.log("getAccountData Error");
            console.log(error);
        }
    }
    const updateAccount = (id: any) => {
        navigate('/main-layout/edit-account', { state: { id: id } });
    }

    const createAccount = () => {
        navigate('/main-layout/create-account')
    }
    const deleteAccount = async (id: any) => {
        console.log('Account Id=' + id);
        try {
            await accountApiService.delteAccount(id);
            toast.success('Account Deleted Successfully', { containerId: 'TR' });
            await getAccountData();
            navigate('/main-layout/account-datatable');
            return;

        } catch (error) {
            console.log("ERROR occured deleteAccount() error=== " + JSON.stringify(error));
        }
    }
    const openAlert = (data: any) => {
        confirmAlert({
            title: "Alert Message",
            message: " Are you sure want to Delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteAccount(data),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };


    useEffect(() => {
        getAccountData();
    }, []);


    return <>
        <Container fluid>
            <Row className="row">
                <Col className="col-sm-12">
                    <Card className="card">
                        <Col className="card-header">
                            <Button type="button" onClick={createAccount}
                                className="btn btn-primary add-row mt-md-0 mt-2">
                                <FontAwesomeIcon icon={faCirclePlus} />
                                &nbsp;Add New
                            </Button>

                        </Col>

                        <Card.Body className="card-body p-4">
                            <Col className="table-responsive table-desi">
                                <Table responsive className=' all-package table-category' striped variant="white">
                                    <thead >
                                        <tr>
                                            <th>Role</th>
                                            <th>First Name</th>
                                            <th>username</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {accountData.map((data: any, idx: any) => (
                                            <tr>
                                                <td data-field="name">{data.role?.name}</td>
                                                <td data-field="name">{data.firstName}</td>
                                                <td data-field="name">{data.username}</td>
                                                <td>
                                                    <a onClick={() => updateAccount(data.id)}>
                                                        <FontAwesomeIcon icon={faPenToSquare} title="Edit Attribute Type" className='me-2 mx-2 font-success' />
                                                    </a>
                                                    <a href="javascript:void(0)" onClick={() => openAlert(data.id)} className="cursor-pointer">
                                                    <FontAwesomeIcon icon={faTrash} title="Delete Attribute" className='me-2 mx-2 delete-btn' />
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>

}
export default AccountDatatable