import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useNavigate } from "react-router-dom";
import ProductApiService from "../../../data/services/product/product-api-service";
import { toast } from "react-toastify";
import axios, { AxiosError } from 'axios';

const AssociatedProducts = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedAlternateProducts, setSelectedAlternateProducts] = useState<any>([]);
    const [selectedRelativeProducts, setSelectedRelativeProduct] = useState<any[]>([]);
    const navigate: any = useNavigate();
    const productApiService: ProductApiService = new ProductApiService();
    const [page, setPage] = useState(1);

    const handleSearch = async (query?: any) => {
        try {
            let url = "?page=" + page + "&pageSize=" + 5;

            if (query) {
                url = url + '&searchKeyword=' + query;
            }
            let result = await productApiService.getAllProduct(url);
            console.log("getDimensionData", result);
            setOptions(result.items);
        } catch (error) {
            console.log("getDimensionData Error");
            console.log(error);
        }
    };

    const handleDelete = (index: number) => {
        const updatedItems = [...selectedAlternateProducts];
        updatedItems.splice(index, 1);
        setSelectedAlternateProducts(updatedItems);
    };

    const handleSelectedAlternateProducts = (selectedItem: any) => {
        try {

            const alternateProducts: any[] = [];
            const alternateProduct: any = selectedItem[0];
            if (!alternateProduct) {
                return;
            }
            for (let j = 0; j < selectedAlternateProducts.length; j++) {
                const alreadySelectedAlternateProduct: any = selectedAlternateProducts[j];
                if (alternateProduct.productId == alreadySelectedAlternateProduct.id) {
                    throw new Error("You can't select same product twice !");
                }
            }
            const product: any = {};
            product.name = alternateProduct.name;
            product.id = alternateProduct.productId;
            alternateProducts.unshift(product);

            setSelectedAlternateProducts([...alternateProducts, ...selectedAlternateProducts]);

        } catch (error: any) {
            toast.error(error.message, { containerId: 'TR' });
        }
    };

    const handleDeleted = (index: number) => {
        const updatedItems = [...selectedRelativeProducts];
        updatedItems.splice(index, 1);
        setSelectedAlternateProducts(updatedItems);
    };


    const handleSelectedRelatedProducts = (selectedItem: any) => {
        try {
            const relatedProducts: any[] = [];
            const relatedProduct: any = selectedItem[0];
            if (!relatedProduct) {
                return;
            }
            for (let j = 0; j < selectedRelativeProducts.length; j++) {
                const alreadySelectedRelatedProduct: any = selectedRelativeProducts[j];
                if (relatedProduct.productId == alreadySelectedRelatedProduct.id) {
                    throw new Error("You can't select same product twice !");
                }
            }
            const product: any = {};
            product.name = relatedProduct.name;
            product.id = relatedProduct.productId;
            relatedProducts.unshift(product);

            setSelectedRelativeProduct([...relatedProducts, ...selectedRelativeProducts]);

        } catch (error: any) {
            toast.error(error.message, { containerId: 'TR' });
        }
    };


    const submitRelatedProduct = () => {
        try {
            validateAndSaveRelativeProductFormData();
        } catch (error: any) {
            console.log(error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const validateAndSaveRelativeProductFormData = async () => {
        try {
            const productFormData = props.productFormData;
            if (props.productFormData.has('alternateProducts')) {
                props.productFormData.delete('alternateProducts');
            }
            props.productFormData.append('alternateProducts', JSON.stringify(constructSelectedAlternateProducts(selectedAlternateProducts)));

            if (props.productFormData.has('relativeProducts')) {
                props.productFormData.delete('relativeProducts');
            }
            props.productFormData.append('relativeProducts', JSON.stringify(constructSelectedRelatedProducts(selectedRelativeProducts)));
            await productApiService.createProduct(productFormData);
            toast.success('Product Saved Successfully', { containerId: 'TR' });
            navigate('/main-layout/product-container');
        } catch (error: any) {
            console.log(error);
            if (axios.isAxiosError(error)) {
                handleAxiosError(error);
                return;
            }
            console.log("Feature Create Failed", error);
            toast.error(error.message, { containerId: 'TR' });
        }

    }
    const handleAxiosError = (error: AxiosError) => {
        if (error.response) {
            const errorMessage = (error.response.data as { message: string }).message;
            toast.error(errorMessage, { containerId: 'TR' });
        } else if (error.request) {
            toast.error('No response from server', { containerId: 'TR' });
        } else {
            toast.error(error.message, { containerId: 'TR' });
        }
    };


    const constructSelectedAlternateProducts = (alternateProducts: any) => {
        const products: any[] = [];
        for (let i = 0; i < alternateProducts.length; i++) {
            const product: any = {};
            product.productId = alternateProducts[i].id;
            products.push(product);
        }
        return products;
    }

    const constructSelectedRelatedProducts = (relatedProducts: any) => {
        const products: any[] = [];
        for (let i = 0; i < relatedProducts.length; i++) {
            const product: any = {};
            product.productId = relatedProducts[i].id;
            products.push(product);
        }
        return products;
    }

    const goBack = () => {
        props.onPreviousCallback();
    }

    return (
        <>
            <Container>
                <Row className="mt-5">
                    <Col className="">
                        <Container fluid>
                            <Col md={3}>
                                <Form.Label>Alternate Products</Form.Label>
                            </Col>
                            <Row className="mt-3">
                                <AsyncTypeahead
                                    filterBy={() => true}
                                    id="product-search-box"
                                    isLoading={isLoading}
                                    labelKey="name"
                                    minLength={3}
                                    onChange={(value) => handleSelectedAlternateProducts(value)}
                                    onSearch={handleSearch}
                                    options={options}
                                    placeholder="Search by products"

                                />

                                {selectedAlternateProducts.length > 0 && (
                                    <Table striped bordered hover className="mt-5 mx-auto w-75">
                                        <thead>
                                            <tr className="text-center">
                                                <th>Alternate Product Name</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedAlternateProducts.map((item: any, index: number) => (
                                                <tr key={index} className="text-center">
                                                    <td>{item.name}</td>
                                                    <td>
                                                        <a href="javascript:void(0)" onClick={() => handleDeleted(index)} className="cursor-pointer">
                                                            <FontAwesomeIcon icon={faTrash} title="Delete Alternate Product" className='me-2 mx-2 delete-btn' />
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}
                            </Row>
                        </Container>
                    </Col>
                </Row>

            </Container>
            <Container>
                <Row className="mt-5">
                    <Col className="">
                        <Container fluid>
                            <Col md={3}>
                                <Form.Label>Related Products</Form.Label>
                            </Col>
                            <Row className="mt-3">
                                <AsyncTypeahead
                                    filterBy={() => true}
                                    id="product-search-box"
                                    isLoading={isLoading}
                                    labelKey="name"
                                    minLength={3}
                                    onChange={(value) => handleSelectedRelatedProducts(value)}
                                    onSearch={handleSearch}
                                    options={options}
                                    placeholder="Search by products"

                                />
                                {selectedRelativeProducts.length > 0 && (
                                    <Table striped bordered hover className="mt-5 mx-auto w-75">
                                        <thead>
                                            <tr className="text-center">
                                                <th>Related Product Name</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedRelativeProducts.map((item: any, index: number) => (
                                                <tr key={index} className="text-center">
                                                    <td>{item.name}</td>
                                                    <td>
                                                        <a href="javascript:void(0)" onClick={() => handleDelete(index)} className="cursor-pointer">
                                                            <FontAwesomeIcon icon={faTrash} title="Delete Related Product" className='me-2 mx-2 delete-btn' />
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}
                            </Row>
                        </Container>
                    </Col>
                </Row>

                <Row className="justify-content-end">
                    <Col>
                        <Col className="pull-rght text-end">
                            <Button type="button" variant="danger" className="my-4 mx-2"
                                onClick={goBack}
                            >back</Button>
                            <Button type="button" className="btn btn-primary my-4 mx-2"
                                onClick={submitRelatedProduct}
                            >Save</Button>

                        </Col>
                    </Col>
                </Row>
            </Container>

        </>
    );
};
export default AssociatedProducts