import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Container, Row, Col, Table, Button, Form } from "react-bootstrap"
import { AsyncTypeahead } from "react-bootstrap-typeahead"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import ProductApiService from "../../../../data/services/product/product-api-service"

const EditAssociatedProducts = (props: any) => {
    const productApiSearch: ProductApiService = new ProductApiService();
    const productApiService: ProductApiService = new ProductApiService();
    const [isLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const navigate: any = useNavigate();
    const [page] = useState(1);
    const [selectedRelativeProducts, setSelectedRelativeProduct] = useState<any[]>([]);
    const [selectedAlternateProducts, setSelectedAlternateProducts] = useState<any[]>([]);

    const handleSearch = async (query?: any) => {
        try {
            let url = "?page=" + page + "&pageSize=" + 5;

            if (query) {
                url = url + '&searchKeyword=' + query;
            }
            let result = await productApiSearch.getAllProduct(url);
            console.log("getDimensionData", result);
            setOptions(result.items);
        } catch (error) {
            console.log("getDimensionData Error");
            console.log(error);
        }
    };

    const updateAlternateProductUI = async (selectedItem: any) => {
        const alternateProducts: any[] = [];
        for (let i = 0; i < selectedItem.length; i++) {
            const alternateProduct: any = selectedItem[i];
            const product: any = {};
            product.name = alternateProduct.relativeProduct.name;
            product.id = alternateProduct.relativeProduct.id;
            alternateProducts.push(product);
        }
        setSelectedAlternateProducts([...alternateProducts]);
    }

    const updateRelativeProductUI = async (selectedItem: any) => {
        const relativeProducts: any[] = [];
        for (let i = 0; i < selectedItem.length; i++) {
            const relativeProduct: any = selectedItem[i];
            const product: any = {};
            product.name = relativeProduct.relativeProduct.name;
            product.id = relativeProduct.relativeProduct.id;
            relativeProducts.push(product);
        }
        setSelectedRelativeProduct([...relativeProducts]);
    }

    const highlightEditProductData = (data: any) => {
        try {
            updateAlternateProductUI(data.alternateProduct);
            updateRelativeProductUI(data.relativeProduct);
        } catch (error: any) {
            throw (error);
        }

    }

    const getProductById = async () => {
        try {
            let data = await productApiService.getProductById(props.productId);
            highlightEditProductData(data);
            console.log("getProductById", data)
        } catch (error) {
            console.log("getProductById Error");
            console.log(error);
        }
    }


    const handleSelectedRelatedProducts = (selectedItem: any) => {
        try {
            const relatedProducts: any[] = [];
            const relatedProduct: any = selectedItem[0];
            if (!relatedProduct) {
                return;
            }
            for (let j = 0; j < selectedRelativeProducts.length; j++) {
                const alreadySelectedRelatedProduct: any = selectedRelativeProducts[j];
                if (relatedProduct.productId == alreadySelectedRelatedProduct.id) {
                    throw new Error("You can't select same product twice !");
                }
            }
            const product: any = {};
            product.name = relatedProduct.name;
            product.id = relatedProduct.productId;
            relatedProducts.unshift(product);

            setSelectedRelativeProduct([...relatedProducts, ...selectedRelativeProducts]);

        } catch (error: any) {
            toast.error(error.message, { containerId: 'TR' });
        }
    };



    const submitRelatedProduct = () => {
        try {
            validateAndSaveRelativeProductFormData();
        } catch (error: any) {
            console.log(error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }


    const constructSelectedAlternateProducts = (alternateProducts: any) => {
        const products: any[] = [];
        for (let i = 0; i < alternateProducts.length; i++) {
            const product: any = {};
            product.productId = alternateProducts[i].id;
            products.push(product);
        }
        return products;

    }

    const validateAndSaveRelativeProductFormData = async () => {
        try {
            const productFormData = props.productFormData;
            if (props.productFormData.has('alternateProducts')) {
                props.productFormData.delete('alternateProducts');
            }
            let alternateProducts :any [] = constructSelectedAlternateProducts(selectedAlternateProducts);
            if (alternateProducts.length == 0) {
                props.productFormData.append('alternateProducts', '');
            } else {
                props.productFormData.append('alternateProducts', JSON.stringify(constructSelectedAlternateProducts(selectedAlternateProducts)));
            }

            if (props.productFormData.has('relativeProducts')) {
                props.productFormData.delete('relativeProducts');
            }
            let relatedProducts: any [] = constructSelectedRelatedProducts(selectedRelativeProducts);
            if (relatedProducts.length == 0) {
                props.productFormData.append('relativeProducts', '');
            } else {
                props.productFormData.append('relativeProducts', JSON.stringify(relatedProducts));
            }

            await productApiService.editProduct(props.productId, productFormData);
            toast.success('Product Updated Successfully', { containerId: 'TR' });
            if (props.handleClose) {
                props.handleClose();
            } else {
                navigate('/main-layout/product-container');
            }
        } catch (error: any) {
            toast.error(error.message, { containerId: 'TR' });
        }
    }
    const constructSelectedRelatedProducts = (alternateProducts: any) => {
        const products: any[] = [];
        for (let i = 0; i < alternateProducts.length; i++) {
            const product: any = {};
            product.productId = alternateProducts[i].id;
            products.push(product);
        }
        return products;
    }

    const handleDeleteAlternateProduct = (index: number) => {
        const updatedItems = [...selectedAlternateProducts];
        updatedItems.splice(index, 1);
        setSelectedAlternateProducts(updatedItems);
    };

    const handleDeleteRelatedProduct = (index: number) => {
        const updatedItems = [...selectedRelativeProducts];
        updatedItems.splice(index, 1);
        setSelectedRelativeProduct(updatedItems);
    };

    const handleSelectedAlternateProducts = (selectedItem: any) => {
        try {
            const alternateProducts: any[] = [];
            const alternateProduct: any = selectedItem[0];
            if (!alternateProduct) {
                return;
            }
            for (let j = 0; j < selectedAlternateProducts.length; j++) {
                const alreadySelectedAlternateProduct: any = selectedAlternateProducts[j];
                if (alternateProduct.productId == alreadySelectedAlternateProduct.id) {
                    throw new Error("You can't select same product twice !");
                }
            }
            const product: any = {};
            product.name = alternateProduct.name;
            product.id = alternateProduct.productId;
            alternateProducts.unshift(product);

            setSelectedAlternateProducts([...alternateProducts, ...selectedAlternateProducts]);

        } catch (error: any) {
            toast.error(error.message, { containerId: 'TR' });
        }
    };

    const goBack = () => {
        props.onPreviousCallback();
    }

    useEffect(() => {
        getProductById();
    }, [props.data, props.productId]);

    return <>

        <Container>
            <Row className="mt-5">
                <Col className="">
                    <Container fluid>
                        <Col md={3}>
                            <Form.Label>Alternate Products</Form.Label>
                        </Col>
                        <Row className="mt-3">
                            <AsyncTypeahead
                                disabled={props.write}
                                filterBy={() => true}
                                id="product-search-box"
                                isLoading={isLoading}
                                labelKey="name"
                                minLength={3}
                                onChange={(value) => handleSelectedAlternateProducts(value)}
                                onSearch={handleSearch}
                                options={options}
                                placeholder="Search by products"
                            />
                            {selectedAlternateProducts.length > 0 && (
                                <Table striped bordered hover className="mt-5 mx-auto w-75">
                                    <thead>
                                        <tr className="text-center">
                                            <th>Alternate Product Name</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedAlternateProducts.map((item: any, index: number) => (
                                            <tr key={index} className="text-center">
                                                <td>{item.name}</td>
                                                <td>
                                                    <a
                                                        href="javascript:void(0)"
                                                        onClick={() => handleDeleteAlternateProduct(index)}
                                                        className={`cursor-pointer ${props.write ? 'unselectable' : ''}`}
                                                        style={{ pointerEvents: props.write ? 'none' : 'auto' }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} title="Delete Alternate Product" className='me-2 mx-2 delete-btn' />
                                                    </a>
                                                    {/* <a href="javascript:void(0)" onClick={() => handleDeleteAlternateProduct(index)} className="cursor-pointer">
                                                        <FontAwesomeIcon icon={faTrash} title="Delete Alternate Product" className='me-2 mx-2 delete-btn' />
                                                    </a> */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            )}
                        </Row>
                    </Container>
                </Col>
            </Row>

        </Container>
        <Container>
            <Row className="mt-5">
                <Col className="">
                    <Container fluid>
                        <Col md={3}>
                            <Form.Label>Related Products</Form.Label>
                        </Col>
                        <Row className="mt-3">
                            <AsyncTypeahead
                                disabled={props.write}
                                filterBy={() => true}
                                id="product-search-box"
                                isLoading={isLoading}
                                labelKey="name"
                                minLength={3}
                                onChange={(value) => handleSelectedRelatedProducts(value)}
                                onSearch={handleSearch}
                                options={options}
                                placeholder="Search by products"
                            />

                            {selectedRelativeProducts.length > 0 && (
                                <Table striped bordered hover className="mt-5 mx-auto w-75">
                                    <thead>
                                        <tr className="text-center">
                                            <th>Related Product Name</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedRelativeProducts.map((item: any, index: number) => (
                                            <tr key={index} className="text-center">
                                                <td>{item.name}</td>
                                                <td>
                                                    <a
                                                        href="javascript:void(0)"
                                                        onClick={() => handleDeleteRelatedProduct(index)}
                                                        className={`cursor-pointer ${props.write ? 'unselectable' : ''}`}
                                                        style={{ pointerEvents: props.write ? 'none' : 'auto' }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} title="Delete Related Product" className='me-2 mx-2 delete-btn' />
                                                    </a>
                                                    {/* <a href="javascript:void(0)" onClick={() => handleDeleteRelatedProduct(index)} className="cursor-pointer">
                                                        <FontAwesomeIcon icon={faTrash} title="Delete Alternate Product" className='me-2 mx-2 delete-btn' />
                                                    </a> */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            )}
                        </Row>
                    </Container>
                </Col>
            </Row>

            <Row className="justify-content-end">
                <Col>
                    <Col className="pull-rght text-end">
                        <Button type="button" variant="danger" className="my-4 mx-2"
                            onClick={goBack}
                        >back</Button>


                        {!props.write && <Button type="button" className="btn btn-primary my-4 mx-2"
                            onClick={submitRelatedProduct}
                        >Update</Button>

                        }
                    </Col>
                </Col>
            </Row>
        </Container>


    </>

}
export default EditAssociatedProducts